import React, { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';

const Pagination = ({page, perPage, setPage, setPerPage, total}) => {

	useEffect(() => {
		if ((page - 1)*perPage > total){
			setPage(1);
		} 
	}, [total])

	const handleChangePage = (event, newPage) => {
		setPage(newPage+1);
	};

	const handleChangeRowsPerPage = (event) => { 
		setPerPage(parseInt(event.target.value, 10));
		setPage(1);
	};

	return (
		<TablePagination
			component="div"
			count={total}
			page={page-1}
			onPageChange={handleChangePage}
			rowsPerPage={perPage}
			labelRowsPerPage="Строк на странице:"
			onRowsPerPageChange={handleChangeRowsPerPage}
		/>
	);
}

export default Pagination;