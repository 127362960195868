import React, { cloneElement, useState } from "react";
import {
    ResourceContextProvider,
    List,
    TopToolbar,
    useListContext
} from "react-admin";

import { Grid } from "@mui/material"; 
import { makeStyles } from '@mui/styles';
import { getDataStoreFilters, DateTimeFilter } from "./filters";
import FilterSidebar from "../../FilterSidbar";
import Pagination from "./Pagination";
import ExportButton from "./ExportButton";

const useStyles = makeStyles((theme) => ({
    root: { 
      margin: "10px", 
      border: '2px solid #DEDEDE', 
      borderRadius: "5px", 
      padding: "10px",
      backgroundColor: theme.palette.background.default
    }
  }));

const ListActions = (props) => (
    <TopToolbar>
        <ExportButton report="data_store_default_report"/>
    </TopToolbar>
);

const DataStoreList = (props) =>{
    const classes = useStyles(); 
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1); 

    const DataStorePagination = props => {
        const { total } = useListContext();
        return <Pagination 
                    rowsPerPageOptions={[10, 25, 50, 100]} 
                    total={total}
                    perPage={perPage}
                    page={page}
                    setPage={setPage}
                    setPerPage={setPerPage}
                />;
    }
    
    return (
        <Grid item xs={12}> 
            <ResourceContextProvider value="data_store" >
                <List   
                    syncWithLocation
                    classes={{root: classes.root}}
                    basePath="data_store" 
                    title="Потребление" 
                    filter={{meter_id: props.meter_id}}
                    filters={getDataStoreFilters(props.setDiffReadingPeriod)}
                    aside={ 
                        <FilterSidebar> 
                            <DateTimeFilter/>
                        </FilterSidebar> }
                    actions={<ListActions/>}
                    filterDefaultValues={{
                        dt__date__gte: new Date(
                            new Date().getTime() - 7 * 24 * 3600 * 1000)
                                .toISOString()
                                .substring(0,10), 
                        dt__date__lt: undefined,
                        diff_reading_period: "dayly"}}
                    pagination={<DataStorePagination />}> 
                        {cloneElement(
                            props.children, 
                            {
                                meter_id: props.meter_id, 
                                paginationData: { page, perPage } })}
                </List>
            </ResourceContextProvider>
        </Grid> 
    )
}

export default DataStoreList;