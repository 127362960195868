import React, { cloneElement } from "react";
import {
    NumberField,
    NumberInput,
    TextField,
    TextInput,
    DateInput,
    DateTimeInput,
    BooleanField,
    BooleanInput,
    FunctionField,
    Datagrid,   
    SelectInput
} from "react-admin"

import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from '@mui/material'; 
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import moment from "moment"; 

export const dateTimeToString = (date) =>  moment(date).format('YYYY-MM-DD HH:MM');
  
export const MyDatagrid = ({hasBulkActions, ...props}) => { 
    return (<Datagrid {...props} hasBulkActions={false} />)
}

export function parseBool(val) {
    if ((typeof val === 'string' && (val.toLowerCase() === 'true' || val.toLowerCase() === 'yes')) || val === 1)
        return true;
    else if ((typeof val === 'string' && (val.toLowerCase() === 'false' || val.toLowerCase() === 'no')) || val === 0)
        return false; 
    return null;
}

export const getDataBySignal = ({rssi, snr}) => {

    if (rssi === null || snr === null) 
        return {
            'color': 'grey',
            'title': ''
        }  

    if (rssi > -115 && snr > -7) 
        return {
            'color': 'green',
            'title': 'Хороший'
        }  

    if (rssi > -115 && snr > -15 || rssi > -126 && snr > -7) 
        return {
            'color': 'yellow',
            'title': 'Удовлетворительный'
        }  
    
    if (rssi > -115) 
        return {
            'color': 'orange',
            'title': 'Плохой: большие помехи'
        }  
    
    if (snr > -7) 
        return {
            'color': 'orange',
            'title': 'Плохой: далеко от БС'
        }  
    return {
        'color': 'red',
        'title': 'Очень плохой'
    }
}

export const getDataByBatLevel = (battery_level=null) => {
    if (battery_level==null) 
        return {
            'color': 'grey',
            'title': ''
        }  

    if (battery_level > 75) 
        return {
            'color': 'green',
            'title': 'Хороший'
        }  

    if (battery_level > 50) 
        return {
            'color': 'yellow',
            'title': 'Удовлетворительный'
        }  

    if (battery_level > 25) 
        return {
            'color': 'orange',
            'title': 'Плохой'
        }  
    return {
        'color': 'red',
        'title': 'Очень плохой'
}
}

// export const updateGateways = (props) => {
//     const { data, ids } = useGetList(
//         'gateway',
//         { page: 1, perPage: 10000 }, 
//     );
    
//     localStorage.setItem("gateways", { data, ids, time: new Date()})
// } 


export const CustomField = ({custom_field_type, ...props}) => {
    switch(custom_field_type) {
        case 'integer':
            return <NumberField {...props} />;
        case 'float':
            return <NumberField {...props} />;
        case 'string':
            return <TextField {...props} />;
        case 'bool':
            return <BooleanField {...props}/>
    }
} 

export const CustomInput = ({custom_input_type, ...props}) => { 
    switch(custom_input_type) {
        case 'integer':
            return <NumberInput {...props} />;
        case 'float':
            return <NumberInput {...props} />;
        case 'string':
            return <TextInput {...props} />;
        case 'bool':
            return <BooleanInput {...props}/>
        case 'date':
            return < DateInput parse={(value) => value ? value : null} {...props} />
        case 'datetime':
            return < DateTimeInput {...props}/>
        case 'select':
            return < SelectInput {...props}/>
        default:
            return <TextInput {...props} />;
    }
} 



export const AddressField = (props) => { 
    const record = useRecordContext(props);
    if (!record) return <span/>
    return <span>{record.street}, {record.house}</span>;
    
}
AddressField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


export const SignalField = (props) => {
    const record = useRecordContext();
    if (!record) return <span />;
    const signal = getDataBySignal(record); 
    return <FunctionField 
        {...props} 
        style={record.rssi ? {        
            borderBottomColor: signal.color,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid'} : {}
        }
        
        render={record => record.rssi && record.snr ? `RSSI: ${record.rssi} | SNR: ${record.snr} - ${signal.title} ` : null} 
        />;
} 

export const BatteryLevelField = (props) => {
    const record = useRecordContext();
    if (!record) return <span />;
    const signal = getDataByBatLevel(record.battery_level); 
    return <FunctionField 
        {...props} 
        style={record.rssi ? {        
            borderBottomColor: signal.color,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid'} : {}
        }
        
        render={record => record.rssi && record.battery_level ? `${parseInt(record.battery_level)}%` : null} 
        />;
} 

 
export const LinkField = (props) => {

    const record = useRecordContext();
    if (!record) return null;
    return <Link href={`#/${props.link}/${record[props.source]}`}>{record[props.optionText]}</Link>
}


export const CopyField = ({ children, ...props }) => {

    const record = useRecordContext();
    return (
        <>
            { cloneElement(children, props) }
            <CopyToClipboard text={ record[props.optionText] } onClick={(e) => { e.preventDefault() }}>
               <FileCopyOutlinedIcon/> 
            </CopyToClipboard>
        </>
    )
}
