import React from 'react'; 
import { required } from 'react-admin';  
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';   
import { useWatch } from 'react-hook-form';
import { CustomInput } from '../helpers'; 

const NodeInputs = () => {
    const dataProvider = useDataProvider();
    const [node, setNode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const node_id = useWatch({name: "node"});
    useEffect(() => {
        if (!node_id){
            if (node) setNode(null);
        } 
        else if ( !node || node_id !== node.id ){
            setLoading(true);
            dataProvider.getOne('node', { id: node_id })
                .then(({ data }) => {
                    setNode(data); 
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
    }, [node_id]);


    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!node?.additional_fields) return null;

    return (
            node.additional_fields.map(({label, name, type, choices, ...props}) => (
                <CustomInput  
                    fullWidth
                    key={name}  
                    label={label} 
                    source={name} 
                    choices={choices}
                    custom_input_type={type} 
                    validate={props.required ? [required()] : null} 
                    fullwidth/>)
        )
    )
};

export default NodeInputs;