import React, { Fragment } from 'react';
import { 
    AutocompleteInput,  
    SimpleForm, 
    TextInput,  
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput, 
    AutocompleteArrayInput,
    SelectInput,  
    FormDataConsumer,
    required, 
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';  
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error, maxLength, minLength } from 'react-admin';
import { Box, Typography } from '@mui/material';
 
import AddressReferenceInput from './AddressReferenceInput';  
const timezones = [
    '+0', '+1', '+2', '+3', '+4', '+5', '+6', '+7', '+8', '+9', '+10', '+11', '+12',
    '-1', '-2', '-3', '-5', '-6', '-7', '-8', '-9', '-10', '-11', '-12'
]


const EUIInput = ({ type, ...props }) => {
    const dataProvider = useDataProvider();
    const [deviceType, setDeviceType] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        if (type) 
            dataProvider.getOne('device/model', { id: type })
                .then(({ data }) => {
                    setDeviceType(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
    }, [type]);

    if (!type) return (
        <TextInput {...props} disabled/>
    )
    
    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!deviceType) return null;

    return (
        <Fragment>
            <TextInput {...props}
                validate={ [maxLength(deviceType.len_of_eui), minLength(deviceType.len_of_eui)] }
            />
            <ReferenceInput  
                source="network_server" 
                reference="network_server"
                perPage={3}
                sort={{ field: 'id', order: 'DESC' }}
                filter={{type__trans_tech_type: deviceType.trans_tech_type}}  
                validate={required()} >
                <AutocompleteInput fullWidth optionText="name" label="Сетевой сервер" />    
            </ReferenceInput> 
        </Fragment>
    )
};

const DeviceEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton alwaysEnable /> 
    </Toolbar>
);


const DeviceForm = props => (
    <SimpleForm {...props} toolbar={<DeviceEditToolbar />}>  
        <Box fullWidth display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Typography variant="h6" gutterBottom>
                    Добавление/Настройка Устройства
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                        flex={1}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <ReferenceInput  
                            source="type" 
                            reference="device/model" 
                            validate={required()} >
                            <AutocompleteInput fullWidth optionText="name" label="Модель устройства" />    
                        </ReferenceInput> 
                        <SelectInput 
                            fullWidth
                            source="timezone" 
                            choices={timezones.map(i => ({ id: parseInt(i), name: i}))}
                            initialValue={(new Date()).getTimezoneOffset()/-60}/>
                        <AddressReferenceInput validate={required()} source="address" reference="address"/> 
                    </Box>
                    <Box
                        flex={1}
                        mr={{ xs: 0, sm: '0.5em' }}
                    >
                        <FormDataConsumer>
                            {({ formData, ...rest }) => 
                                <EUIInput 
                                    source="eui" 
                                    label='EUI' 
                                    type={formData.type} 
                                    fullWidth
                                    validate={required()} />
                            }
                        </FormDataConsumer>  
                        <ReferenceArrayInput  
                            source="node_service_company" 
                            reference="service_company"
                            validate={required()}
                            >
                                <AutocompleteArrayInput 
                                    fullWidth 
                                    label="Сервисная компания" 
                                    optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`} />
                        </ReferenceArrayInput>  
                    </Box>
                </Box>
                <TextInput source="description" label="Описание" fullWidth/>
            </Box>
                      
        </Box>
    </SimpleForm>
)

export default DeviceForm;