import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/GetApp';
import {
    Button,
    useDataProvider,
    useNotify,
    useListContext
} from 'react-admin';
import axios from 'axios';

import { saveAs } from 'file-saver';

import { API_URL } from '../dataProvider';

const ExportButton = props => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        report,
        reportFilterValues,
        ...rest
    } = props;
    const {
        filterValues,
        filter,
        resource,
        sort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props); 

    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify(); 
    const [loading, setLoading] = React.useState(false);
    var intervalId;
    const checkTask = (task_id) => { 
        axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
        axios
            .get(`${API_URL}/api/v1/downloads/${task_id}`, {responseType: 'blob'})
            .then(
                ({ data, status, ...props}) => {
                    if (status==202){
                        
                    }
                    else {
                        setLoading(false);
                        clearInterval(intervalId);

                        // Используйте saveAs для сохранения Blob в файле с указанным именем
                        saveAs(data, "report");


                    }
                }
            )
            .catch(error => {
                setLoading(false);
                clearInterval(intervalId);
                console.error(error);
                notify('ra.notification.http_error', 'warning');
            }); 
    }
    const handleClick = useCallback(
        event => { 
            const filterValues_ = {...filterValues}
            const reportFilterValues_ = {...reportFilterValues}
            Object.keys(filterValues_).forEach(key => filterValues_[key] === undefined && delete filterValues_[key])
            Object.keys(reportFilterValues_).forEach(key => reportFilterValues_[key] === undefined && delete reportFilterValues_[key])

            axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
            axios
                .get(`${API_URL}/api/v1/${resource}/?`
                        + `${new URLSearchParams(filterValues_).toString()}&`
                        + `${new URLSearchParams(reportFilterValues_).toString()}&`
                        + `${new URLSearchParams(filter).toString()}&`
                        + `ordering=${sort.order === 'ASC' ? "-": ""}${sort.field}&`
                        + `page=1&page_size=${maxResults}&`
                        + `report=${report}`
                    )
                .then(
                    ({ data }) => {
                        if (data.task_id){
                            setLoading(true);
                            intervalId = setInterval(function() {
                                // Вызываем функцию с передачей аргументов
                                checkTask(data.task_id);
                            }, 1000);
    
                            // Устанавливаем таймер на остановку через 5 минут (300 000 миллисекунд)
                            setTimeout(function() {
                                // Очищаем интервал после пяти минут
                                clearInterval(intervalId);
                                setLoading(false);
                                console.log("Прошло пять минут. Интервал остановлен.");
                            }, 300000);
                        } 
                    }
                )
                .catch(error => {
                    setLoading(false);
                    clearInterval(intervalId);
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            sort,
            dataProvider,
            exporter,
            filterValues,
            reportFilterValues,
            maxResults,
            notify,
            onClick,
            resource, 
        ]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
    basePath,
    filterValues,
    resource,
    ...rest
}) =>
    rest;

ExportButton.propTypes = {
    basePath: PropTypes.string,
    exporter: PropTypes.func,
    filterValues: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number,
    resource: PropTypes.string,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    icon: PropTypes.element,
};

export default ExportButton;