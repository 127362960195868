import React, { useState, useEffect } from 'react'; 
import {  
    useListContext, 
    useGetList, 
    Loading, 
} from 'react-admin';  
import { makeStyles } from '@mui/styles';
import { Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { YMaps, Map, Placemark, Clusterer, Circle } from 'react-yandex-maps'; 

import { getDataBySignal } from "../helpers";
import redDot from "../icons/redDot.png" 
import greenDot from "../icons/greenDot.png"
import yellowDot from "../icons/yellowDot.png"
import orangeDot from "../icons/orangeDot.png"
import greyDot from "../icons/greyDot.png"
import blueDot from "../icons/blueDot.png"

import { Card, CardContent } from "../components/CustomCard";

const dots = { 
    redDot,
    greenDot,
    yellowDot,
    orangeDot,
    greyDot,
    blueDot
}

const useStyles = makeStyles({
    mapDiv: { 
        height: '100%',
        width: "100%", 
    },
    map: {
        height: '60vh',
        width: "100%", 
    },
    root: { width: "100%", margin: 'auto', 
    backgroundColor: "grey"},
});


const getGatewayHitContent = (gateway) => (
    `<div style="padding: 5px">
        <h3>Шлюз</h3>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>EUI:</b>&nbsp;${gateway.eui}
        </div>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>Описание:</b>&nbsp;${gateway.description}
        </div>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>Последнее сообщение:</b>&nbsp;${gateway.sent_date?.toLocaleString()}
        </div> 
    </div>`
);

const getDeviceHitContent = (device, signalLevel) => (
    `<div style="padding: 5px">
        <h3>Устройство СПД</h3>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>EUI:</b>&nbsp;${device.eui}
        </div>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>Описание:</b>&nbsp;${device.description}
        </div>
        <div style="border-top: 1px solid gray; margin: 5px;">
            <b>Последнее сообщение:</b>&nbsp;${device.sent_date?.toLocaleString()}
        </div> 
        <div style="border-top: 1px solid gray; margin: 5px;">
            <div style="border-bottom: 2px solid ${signalLevel.color};">
                <b>Качество покрытия:</b>&nbsp;${signalLevel.title}
            </div>
        </div> 
    </div>`
);

const GatewayCoverage = ({ gateway }) => {
    return [
        <Circle 
            key={gateway.id+"big"} 
            geometry={[[gateway.lat, gateway.lng], 2000]} 
            options={{ 
                // Цвет заливки.
                // Последний байт (77) определяет прозрачность.
                // Прозрачность заливки также можно задать используя опцию "fillOpacity".
                fillColor: "#02d5b977"
            }} 
            
            />,
        <Circle 
            key={gateway.id+"small"} 
            geometry={[[gateway.lat, gateway.lng], 500]} 
            options={{ 
                // Цвет заливки.
                // Последний байт (77) определяет прозрачность.
                // Прозрачность заливки также можно задать используя опцию "fillOpacity".
                fillColor: "#0cb8e277"
            }} 
         
            />,
        ]
}

const DeviceLocationMap = ({...props}) => { 
    const apiKey = "db24f8e9-c6fe-438a-bc18-e4c125364ba7"; 
    const classes = useStyles(); 
    const [zoom, setZoom] = useState(13);
    const [center, setCenter] = useState([ 48.11, 66.22 ]);  
    const devices = useListContext(); 
    
    useEffect(() => {
        if (devices?.data?.length > 0){
            const device = devices.data.find(({address}) => address);
            if (device) setCenter([device.lat, device.lng]);
        }
    },[devices.data])
 
    let gateways = JSON.parse(localStorage.getItem("gateways")); 
    if (gateways && new Date() - gateways.time > 10 * 60 * 1000 || gateways?.data?.length < 10) gateways = null; // 10 minut unterval
    
    const { data, isLoading, error } = useGetList(
        'gateway',
        {
            pagination: { page: 1, perPage: gateways ? 1 : 10000 }, 
        }
         
    ); 
    if (isLoading) { return <Loading />; }
    if (error) {  
        return <p>Возникла ошибка при запросе списка базовых станции</p>; }
  
    if (data.length > 1) 
        localStorage.setItem("gateways", 
            JSON.stringify({ data, time: new Date()})); 

    gateways = JSON.parse(localStorage.getItem("gateways"));
    if(!gateways?.data) return null;
    
    return (   
        <Card> 
            <Accordion> 
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    > 
                    <Typography>Карта</Typography> 
                </AccordionSummary> 
                <AccordionDetails style={{display: "block"}}>      
                    <CardContent> 
                        <YMaps  query={{apikey: apiKey}}>
                            <div className={classes.mapDiv}>
                                <Map className={classes.map}  
                                    defaultState={{ 
                                        center, 
                                        zoom
                                    }} 
                                    state={{ center, zoom }} 
                                    modules={["package.full"]} 
                                    onBoundsChange={
                                        ({_cache: { newZoom, newCenter }}) => {
                                            setZoom(newZoom);
                                            setCenter(newCenter);
                                        } } 
                                > 
                                    <Clusterer 
                                        options={{
                                            clusterIconLayout: "default#pieChart",
                                            clusterIconPieChartRadius: 16,
                                            clusterIconPieChartCoreRadius: 12,
                                            clusterIconPieChartStrokeWidth: 1,
                                            clusterDisableClickZoom: true,
                                            clusterHideIconOnBalloonOpen: false,
                                            geoObjectHideIconOnBalloonOpen: false, 
                                            hintContent: "Cluster"
                                          }}
                                        
                                        
                                        >         
                                        { 
                                            !devices.isLoading && 
                                            devices.data
                                                .map((device, idx) => { 
                                                    if (!device.address) return null;
                                                    const signalLevel = getDataBySignal(device); 
                                                    let size = 1.5*zoom/2; 
                                                    return (
                                                        <Placemark 
                                                            key={device.id} 
                                                            geometry={[device.lat, device.lng]}  
                                                            properties={{
                                                                iconCaption: idx,
                                                                hintContent: getDeviceHitContent(device, signalLevel),
                                                                clusterCaption: device.eui,
                                                                iconContent: zoom > 15 ? 
                                                                    `<div style="margin-left:15px">${device.id}</div>` : null,
                                                                balloonContent:  getDeviceHitContent(device, signalLevel),
                                                            }}
                                                            options={{ 
                                                                iconLayout: 'default#imageWithContent', 
                                                                iconImageHref: dots[`${signalLevel.color}Dot`],
                                                                iconImageSize: [size, size], 
                                                                iconImageOffset: [-size, -size],
                                                                zIndex: 10000,
                                                                iconColor: signalLevel.color
                                                            }} 
                                                            />) 
                                                    }
                                                )
                                        }
                                    </Clusterer>
                                    
                                    
                                    { 
                                        gateways.data
                                            .filter(gateway => Math.abs(center[0] - gateway.lat) < 0.5
                                                        && Math.abs(center[1] - gateway.lng) < 0.5)
                                            .map(gateway => {  
                                                let gateway_choosed = devices.filterValues.gateway === gateway.id;
                                                if (gateway_choosed && center[0] !== gateway.lat) setCenter([gateway.lat, gateway.lng]);
                                                let size = gateway_choosed ? zoom * 2 : zoom ; 
                                                return [
                                                    <Placemark  
                                                        key={gateway.id} 
                                                        geometry={[gateway.lat, gateway.lng]} 
                                                        properties={{
                                                            // iconContent: gateway_choosed ? `<div style="margin:20px">${gateway.eui}</div>` : null,
                                                            hintContent: getGatewayHitContent(gateway), 
                                                            gateway_id: gateway.id,
                                                        }}
                                                        options={{
                                                            iconLayout: 'default#imageWithContent',
                                                            iconImageHref: blueDot,
                                                            iconImageSize: [size, size], 
                                                            iconImageOffset: [-size/2, -size/2]
                                                        }} 
                                                        instanceRef={ref => { 
                                                            ref && ref.events.add('click', 
                                                                (e) => {  
                                                                    console.log(e);
                                                                    devices.setFilters({
                                                                            gateway: e.originalEvent.target.properties._data.gateway_id })
                                                                });
                                                            }} 
                                                        />,
                                                        gateway_choosed && <GatewayCoverage gateway={gateway} /> 
                                                    ]
                                            }) 
                                    }
                                     
                                </Map>
                            </div>
                        </YMaps> 
                    </CardContent>
                </AccordionDetails>
            </Accordion>
        </Card>
    )
}



export default DeviceLocationMap;