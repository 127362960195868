import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { 
    AutocompleteInput, 
    ReferenceInput, 
    required
} from 'react-admin';  
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Error } from 'react-admin';   
import AddressReferenceInput from '../devices/AddressReferenceInput';

const DeviceModeInput = () => { 
    const dataProvider = useDataProvider();
    const [device, setDevice] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const { setValue } = useFormContext();
    const device_id = useWatch({ name: 'device' }); 
    const device_mode = useWatch({ name: 'device_mode' }); 
    const device__address = useWatch({ name: 'device__address' }); 
    useEffect(() => {
        if (!device_id) {
            if (!!device_mode) setValue('device_mode', undefined); 
        }
        else if ((!device || device_id !== device.id) && !loading){
            setValue("device__address", undefined);
            setLoading(true); 
            dataProvider.getOne('device', { id: device_id })
                .then(({ data }) => {
                    if (device && device_id !== device.id) 
                        setValue('device_mode', undefined); 
                    setDevice(data);
                    setValue("device__address", data.address);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
            
        }
    }, [device_id]);

    
    if (loading) return <Loading />;
    if (error) { 
        return <Error/>
    }
    if (!device) return null;  
        
    return (
        <>
            <AddressReferenceInput 
                source="device__address" 
                reference="address"
                validate={required()}
                /> 
            <ReferenceInput  
                source="device_mode" 
                reference="device/mode" 
                filter={{device_model: device.type}}>
                <AutocompleteInput 
                    optionText="name" 
                    fullWidth 
                    validate={[required()]} 
                    label="Режим порта устройства" />    
            </ReferenceInput>
        </>
    )
};

export default DeviceModeInput;