import React from 'react';
import {
    required,
    Button,
    SaveButton,
    Edit,
    Create,
    SimpleForm,
    Toolbar,
    TextInput,
    ReferenceInput, 
    AutocompleteInput,
    AutocompleteArrayInput, 
    ReferenceArrayInput
} from 'react-admin';  
import IconCancel from '@mui/icons-material/Cancel';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { makeStyles } from '@mui/styles';
import QuickCreateReferenceInput from '../components/QuickCreateInput'; 
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
    input: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        width: '100%'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const Title = ({ record }) => {
    return <span>{record ? `/${record.name}` : ''}</span>;
};


function NodeQuickEditButton({ onClose }) {
    const { id, parent_id } = useParams();
    const classes = useStyles(); 
    const handleCloseClick = () => {
         onClose()
    };

    const CustomToolbar = props => (
        <Toolbar {...props} className={classes.toolbar}>
            <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
            >
                <IconCancel />
            </Button>
            <SaveButton onClick={handleCloseClick}/>
        </Toolbar>
    );
    const NodeForm = (props) => {  
        return (
        <SimpleForm toolbar={<CustomToolbar/>} {...props} redirect={() => ''}> 
            <TextInput 
                source="name" 
                label='Имя'
                validate={[required()]} 
                className={classes.input}/>  
            <QuickCreateReferenceInput 
                fullWidth
                label="Тип узла" 
                source="type" 
                reference="node/type" 
                suggestionLimit={30}
                validate={[required()]} 
                inputs={[<TextInput source='name' label="Название"/>]}
                className={classes.input}/> 
            <ReferenceInput 
                fullWidth 
                source="parent" 
                reference="node"  
                validate={[required()]} 
                className={classes.input}>
                <AutocompleteInput 
                    fullWidth 
                    label="Родитель"
                    optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`}/>     
            </ReferenceInput> 
            <TextInput 
                label="Номер телефона" 
                source="phone"  />
            <TextInput 
                source="account_id" 
                label='Лицевой счет/№ договора' 
                className={classes.input}/> 
            <ReferenceArrayInput  
                source="users" 
                reference="user" 
                className={classes.input}>
                <AutocompleteArrayInput 
                    label="Пользователи" 
                    optionText="username" />
            </ReferenceArrayInput>
            <TextInput 
                source="description" 
                label="Описание" 
                className={classes.input}/> 
        </SimpleForm>
    )}
    return (
        <>
            <Dialog
                fullWidth
                open={true}
                onClose={handleCloseClick}
                aria-label="Добавить/Изменить узел"
            >
                <DialogTitle>Добавить/Изменить узел</DialogTitle>
                    <DialogContent>
                        {id &&
                            <Edit title={<Title/>} id={id} basePath="node" resource="node">
                                <NodeForm />
                            </Edit>}
                        {parent_id &&
                            <Create title={<span>/Добавить</span>} basePath="node" resource="node">
                                <NodeForm defaultValues={{parent: parseInt(parent_id)}}/>
                            </Create>
                        }
                    </DialogContent> 
            </Dialog>
        </>
    );
}

export default NodeQuickEditButton;
