// in ./CustomResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    SimpleForm,
    TextInput,
    SaveButton,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar, 
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectInput,
    NumberInput,
    NumberField,
    TextField,
    DateInput,
    BooleanInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import UpdateIcon from '@mui/icons-material/Update'; 
import EditIcon from '@mui/icons-material/Edit';  
import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';


import NodeReferenceInput from '../nodes/NodeReferenceInput'; 
import DeviceModeInput from './DeviceModeInput';
import DeviceModeInputs from './DeviceModeInputs'; 
import NodeInputs from './NodeInputs';
import QuickCreateInput from '../components/QuickCreateInput';


const useStyles = makeStyles((theme) => {
  
    return (
        { 
        box: {
            border: '2px solid #DEDEDE',
            borderRadius: '5px',
            backgroundColor: 'white', 
            padding: "10px",
            margin: "5px",
        }
    })
});

const UpdateMeters = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('meter');
    const [updateMany, { isLoading }] = useUpdateMany();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const classes = useStyles();


    const handleConfirm = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === "" || data[key] == null || (Array.isArray(data[key]) && data[key].length === 0)) {
                delete data[key];
            }
        });
        const ids = data.meter_ids;
        delete data.meter_ids; 
        updateMany(
            'meter', 
            { 
                ids,
                data
            },
            {
                onSuccess: () => {
                    refresh();
                    notify('Изменения приняты!');
                    unselectAll();
                },
                onFailure: error => notify('Ошибка: изменения не приняты', 'warning'),
            }

        );
        setOpen(false);
    };

    const MeterEditToolbar = () => (
        <Toolbar fullWidth>
            <Button
                label="ra.action.cancel"
                onClick={handleDialogClose}
                disabled={isLoading}
            >
                <IconCancel />
            </Button>
            <SaveButton sx={{ marginLeft: "auto" }}/>
        </Toolbar>
    );

    return (
        <Fragment>
            <Button label="Изменить" onClick={handleClick}><EditIcon /></Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle>Изменить</DialogTitle>
        
                <DialogContent> 
                    <SimpleForm toolbar={<MeterEditToolbar/>} 
                        onSubmit={handleConfirm} 
                        defaultValues={{meter_ids: selectedIds}}> 
                        <ReferenceArrayInput  
                            source="meter_ids" 
                            reference="meter" 
                            >
                            <AutocompleteArrayInput 
                                fullWidth 
                                validate={required()}
                                disableClearable
                                clearOnBlur={false}
                                label="Измерители" 
                                optionText={choice => choice && `${choice.serial_number} | ${choice.description}`} />
                        </ReferenceArrayInput> 

                        <Box display={{ xs: 'block', sm: 'flex' }} >
                    <Box flex={2}>
                        <Box className={classes.box}>

                            <NodeReferenceInput 
                                label="Узел"
                                source="node"
                                reference="node" 
                                perPage={10}
                                inputs={
                                    <>
                                        <TextInput 
                                            fullWidth
                                            source="name" 
                                            label='Имя'
                                            
                                            />  
                                        <QuickCreateInput  
                                            label="Тип узла" 
                                            source="type" 
                                            reference="node/type" 
                                            suggestionLimit={30}
                                            
                                            inputs={[<TextInput source='name' label="Название"/>]}
                                            /> 
                                        <ReferenceInput 
                                            label="Родитель" 
                                            source="parent" 
                                            reference="node"  
                                            
                                            >
                                            <AutocompleteInput fullWidth optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`}/>     
                                        </ReferenceInput> 
                                        <TextInput 
                                            fullWidth
                                            label="Номер телефона" 
                                            source="phone"  />
                                        <TextInput 
                                            fullWidth
                                            source="account_id" 
                                            label='Лицевой счет/№ договора' 
                                            /> 
                                        <ReferenceArrayInput 
                                            label="Пользователи" 
                                            source="users" 
                                            reference="user" 
                                            >
                                            <AutocompleteArrayInput fullWidth optionText="username" />
                                        </ReferenceArrayInput>
                                        <TextInput 
                                            fullWidth
                                            source="description" 
                                            label="Описание" 
                                            /> 
                                    </>
                                }
                                
                            /> 
                            <Box display={{ xs: 'block', sm: 'flex' }} >
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <QuickCreateInput 
                                        fullWidth
                                        label="Модель измерителя"
                                        source="type"
                                        reference="meter%2fmodel" 
                                        perPage={30}
                                        
                                        optionText={({name, proportionality_factor}) => (`${name}, k: ${proportionality_factor}`)}
                                        inputs={
                                            <>
                                                <TextInput 
                                                    fullWidth
                                                    source="name" 
                                                    label='Название' 
                                                    /> 
                                                <NumberInput 
                                                    fullWidth
                                                    source="proportionality_factor" 
                                                    label="Коэффициент пропорциональности" 
                                                    /> 
                                                <TextInput 
                                                    fullWidth
                                                    source="unit_of_measurement" 
                                                    label="Единица измерения" 
                                                    />
                                                <TextInput 
                                                    fullWidth
                                                    source="description" 
                                                    label="Описание"/>
                                            </>
                                        }
                                        fields={ 
                                            [
                                                <TextField fullWidth source="name" label='Название'/>,
                                                <NumberField fullWidth source="proportionality_factor" label="Коэффициент пропорциональности"/>,
                                                <TextField fullWidth source="unit_of_measurement" label="Единица измерения" />,
                                                <TextField fullWidth source="description" label="Описание"/>
                                            ]
                                        }/>
                                    <ReferenceInput   
                                        source="resource_type" 
                                        reference="resource_type">
                                        <SelectInput 
                                            fullWidth 
                                            optionText="name" 
                                            label="Тип ресурса" 
                                            />
                                    </ReferenceInput>
                                    <DateInput 
                                        fullWidth source="join_date" 
                                        label="Дата подключения"
                                        /> 
                                     <NodeInputs /> 
                                </Box>

                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >     
                                    <NumberInput 
                                        fullWidth 
                                        source="join_reading" 
                                        label="Показание при подключении" 
                                        />    
                                    <QuickCreateInput 
                                        fullWidth 
                                        label="Место установки" 
                                        source="installation_place"
                                        reference="installation_place"
                                        perPage={30}
                                        
                                        inputs={
                                            <TextInput source="name" label="Название" />
                                        }/> 
                                </Box>
                            </Box> 
                        </Box> 
                        <Box className={classes.box}>
                            <Typography variant="h6" gutterBottom>
                                Потребитель
                            </Typography> 
                            <TextInput 
                                source="consumer" 
                                label='Название/ФИО' 
                                fullWidth/>  
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >                                      
                                    <SelectInput  
                                        source="client_sector" 
                                        label='Клиентский сектор'  
                                        choices={[
                                            {
                                                id: 'legal',
                                                name: 'Юридический сектор'
                                            },
                                            {
                                                id: 'private',
                                                name: 'Частный сектор'
                                            },
                                            {
                                                id: 'multi_apartment',
                                                name: 'Многоквартирный сектор'
                                            },
                                            {
                                                id: 'physical',
                                                name: 'Физический сектор'
                                            }
                                        ]}
                                        
                                        fullWidth/>    
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >  
                                    <QuickCreateInput 
                                        fullWidth
                                        label="Тип объекта" 
                                        source="object_type" 
                                        reference="object_type" 
                                        
                                        inputs={
                                            <TextInput source="name" label="Название" />
                                        }/>  
                                </Box> 
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <TextInput 
                                        source="apartment" 
                                        label='Квартира'
                                        fullWidth/> 
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <TextInput 
                                        source="account_id" 
                                        label='Лицевой счет/№ договора' 
                                        fullWidth/> 
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <TextInput 
                                        source="phone" 
                                        label='Номер телефона' 
                                        fullWidth/> 
                                </Box> 
                            </Box>
                        </Box>
                    </Box> 
                    {/* <Box flex={1}>
                        <Box className={classes.box} sx={{marginLeft: "10px"}}>
                            <Typography variant="h6" gutterBottom>
                                Устройство СПД
                            </Typography>
                            <ReferenceInput 
                                source="device" 
                                reference="device">
                                <AutocompleteInput 
                                    fullWidth  
                                    optionText="eui" 
                                    
                                    matchSuggestion={() => true} 
                                    label="Устройство СПД"/>    
                            </ReferenceInput>  
                            <DeviceModeInput />   
                            <DeviceModeInputs />
                        </Box> 
                        
                    </Box> */}
                </Box>
                <TextInput fullWidth multiline source="description" label="Описание"/>
                {/*<BooleanInput source='is_active' label="Вкл/Выкл" />*/}
                    </SimpleForm>
                    
                </DialogContent> 
                <DialogActions>
                   
                </DialogActions>
            </Dialog>
            {/* <Confirm
                isOpen={open}
                loading={isLoading}
                title="Обновить состояние"
                content="Вы действительно хотите обновить состояние для данных устройств?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> */}
        </Fragment>
    );
}

export default UpdateMeters;