import drfProvider, { fetchJsonWithAuthToken, fetchJsonWithAuthJWTToken } from 'ra-data-django-rest-framework';

let authProvider;
let dataProvider;
const useJWTAuth = false;//parseBool(process.env.REACT_APP_USE_JWT_AUTH);
const API_URL = "https://sm.iot-exp.kz"

function _extends() {
    _extends = Object.assign || function (target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
  
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
  
      return target;
    };
  
    return _extends.apply(this, arguments);
  }

function jwtTokenAuthProvider(options) {
    if (options === void 0) {
      options = {};
    }
  
    var opts = _extends({
      obtainAuthTokenUrl: '/api/token/'
    }, options);
  
    return {
      login: function (_ref) {
        var username = _ref.username,
            password = _ref.password;
  
        try {
          var request = new Request(opts.obtainAuthTokenUrl, {
            method: 'POST',
            body: JSON.stringify({
              username: username,
              password: password
            }),
            headers: new Headers({
              'Content-Type': 'application/json'
            })
          });
          return Promise.resolve(fetch(request)).then(function (response) {
            var _exit = false;
  
            function _temp2(_result) {
              if (_exit) return _result;
  
              if (response.headers.get('content-type') !== 'application/json') {
                throw new Error(response.statusText);
              }
  
              return Promise.resolve(response.json()).then(function (json) {
                var error = json.non_field_errors;
                throw new Error(error || response.statusText);
              });
            }
  
            var _temp = function () {
              if (response.ok) {
                return Promise.resolve(response.json()).then(function (responseJSON) {
                  localStorage.setItem('access', responseJSON.access);
                  localStorage.setItem('refresh', responseJSON.refresh);
                  _exit = true;
                });
              }
            }();
  
            return _temp && _temp.then ? _temp.then(_temp2) : _temp2(_temp);
          });
        } catch (e) {
          return Promise.reject(e);
        }
      },
      logout: function logout() {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        return Promise.resolve();
      },
      checkAuth: function checkAuth() {
        return localStorage.getItem('access') ? Promise.resolve() : Promise.reject();
      },
      checkError: function checkError(error) {
        var status = error.status;
  
        if (status === 401) {
          localStorage.removeItem('access');
          localStorage.removeItem('refresh');
          return Promise.reject();
        }
  
        return Promise.resolve();
      },
      getPermissions: function getPermissions() {
        return Promise.resolve();
      }
    };
}

function tokenAuthProvider(options) {
  if (options === void 0) {
    options = {};
  }

  var opts = _extends({
    obtainAuthTokenUrl: '/api-token-auth/'
  }, options);

  return {
    login: function (_ref) {
      var username = _ref.username,
          password = _ref.password;

      try {
        var request = new Request(opts.obtainAuthTokenUrl, {
          method: 'POST',
          body: JSON.stringify({
            username: username,
            password: password
          }),
          headers: new Headers({
            'Content-Type': 'application/json'
          })
        });
        return Promise.resolve(fetch(request)).then(function (response) {
          var _exit = false;

          function _temp2(_result) {
            if (_exit) return _result;

            if (response.headers.get('content-type') !== 'application/json') {
              throw new Error(response.statusText);
            }

            return Promise.resolve(response.json()).then(function (json) {
              var error = json.non_field_errors;
              throw new Error(error || response.statusText);
            });
          }

          var _temp = function () {
            if (response.ok) {
              var _localStorage2 = localStorage,
                  _setItem2 = _localStorage2.setItem;
              return Promise.resolve(response.json()).then(function (_response$json) {
                _setItem2.call(_localStorage2, 'token', _response$json.token);

                _exit = true;
              });
            }
          }();

          return _temp && _temp.then ? _temp.then(_temp2) : _temp2(_temp);
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    logout: function logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('nodes'); 
      return Promise.resolve();
    },
    checkAuth: function checkAuth() {
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    checkError: function checkError(error) {
      var status = error.status;
      if (status === 403) { 
        throw new Error(error);
      }
      if (status === 401) {
        localStorage.removeItem('token');
        return Promise.reject();
      }

      return Promise.resolve();
    },
    getPermissions: function getPermissions() {
      return Promise.resolve();
    }
  };
}

if (useJWTAuth) {
    console.log("Using rest_framework_simplejwt.authentication.JWTAuthentication");
    authProvider = jwtTokenAuthProvider({obtainAuthTokenUrl: "/api/token/"});
    dataProvider = drfProvider(`${API_URL}/api/v1`, fetchJsonWithAuthJWTToken);
} else {
    console.log("Using rest_framework.authentication.TokenAuthentication");
    authProvider = tokenAuthProvider();
    dataProvider = drfProvider(`${API_URL}/api/v1`, fetchJsonWithAuthToken);
}

export {
    authProvider,
    dataProvider,
    API_URL
}