import React from 'react'; 
import {
    Datagrid, 
    DateField,   
    NumberField, 
    SimpleShowLayout
} from 'react-admin';   
import { JsonField } from "react-admin-json-view";

import { Card, CardHeader, CardContent } from '../../../components/CustomCard';  
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';

const Expand = (props) => {
    return(
        <div>
            <SimpleShowLayout>  
                <NumberField {...props} 
                    source="W_total" 
                    label="W полная, кВт*ч"/> 
                <NumberField {...props} 
                    source="W_reactive" 
                    label="W реактивный, кВт*ч"/> 
                <NumberField {...props} 
                    source="P" 
                    label="P, кВт"/> 
                <NumberField {...props} 
                    source="U" 
                    label="U, В"/> 
                <NumberField {...props} 
                    source="I" 
                    label="I, A"/> 
                <NumberField {...props} 
                    source="cos(phase)" 
                    label="Cos(ϕ)"/> 
                <NumberField {...props} 
                    source="f" 
                    label="Частота, Гц"/> 
            </SimpleShowLayout>
   
                <JsonField
                    {...props}
                    label="Пакет"
                    source="data"
                    addLabel={true}
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                    }}
                /> 
        </div>
    )
}

const ListChild = props => {   
    return(
        <Datagrid {...props} expand={<Expand/>}> 
            <DateField source="dt" label="Дата/Время" showTime/>
            <NumberField source="reading" label="Активная потребленная электрооэнергия, кВт*ч"/> 
            <NumberField source="diff_reading" label="Расход энергии, кВт*ч"/>  
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}


const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return (  
        <Card>
            <CardHeader title="Потребленная энергия"/> 
            <CardContent> 
                <CustomChart 
                    key={1}
                    chartData={chartData} 
                    YLabel="Активная, кВт*ч"
                    YDataKey="W"
                    lineColor="red"
                    diffReadingPeriod={diffReadingPeriod}/> 
                <CustomChart 
                    key={1}
                    chartData={chartData} 
                    YLabel="Расход, кВт*ч"
                    YDataKey="diff_reading"
                    lineColor="black"
                    diffReadingPeriod={diffReadingPeriod}/> 
            </CardContent>
        </Card>    
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)
 

export default DataStorePage;
 

 