/* eslint react/jsx-key: off */
import React from 'react';
import { Admin, Resource, Login} from 'react-admin'; // eslint-disable-line import/no-unresolved 
import { render } from 'react-dom';   
import i18nProvider from './i18nProvider';
import Layout from './Layout';
import devices from './devices';
import nodes from './nodes';
import meters from './meters';
import meter_models from './meter_models';
import meter_installations from './meter_installations';
import users from './users';
import events from './event'; 

import {authProvider, dataProvider} from "./dataProvider";
import backgroundImage from "./backgroundImage.jpeg"
import { lightTheme } from './themes';
 
const MyLoginPage = () => (
    <Login
        // A random image that changes everyday
        backgroundImage={backgroundImage}/>
);

render(
    <React.Fragment>
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            title="Smart Metrix"
            layout={Layout}
            theme={lightTheme}
            initialState={ { admin: { ui: { sidebarOpen: false, viewVersion: 0 } } } }
            loginPage={MyLoginPage}
            
            
        >
            {permissions => [
                <Resource name="node" options={{ label: 'Узлы' }} {...nodes}/>,
                <Resource name="node/type" />,
                <Resource name="device/mode" />,
                <Resource name="user" {...users} options={{ label: 'Доступ' }}/>,
                <Resource name="device" {...devices} options={{ label: 'Устройства СПД' }}/>,
                <Resource name="device/model" />, 
                <Resource name="meter" options={{ label: 'Измерители' }} {...meters} />, 
                <Resource name="meter_installation" options={{ label: 'Монтаж Измерителей' }} {...meter_installations}/>, 
                <Resource name="meter%2fmodel" options={{ label: 'Модели Измерителей' }} {...meter_models}/>, 
                <Resource name="dealer_company" />, 
                <Resource name="service_company" />, 
                <Resource name="resource_type" />,
                <Resource name="object_type" />,
                <Resource name="installation_place" />,
                <Resource name="data_store" />,
                <Resource name="reading_correction" />,
                <Resource name="data_store_default" />,
                <Resource name="gateway" />,
                <Resource name="address" />,
                <Resource name="location" />,
                <Resource name="event" options={{ label: 'Журнал событии' }} {...events}/>,
                <Resource name="event_type" />,
            ]}
        </Admin>
        <div style={{
                position: 'fixed', right: 0, bottom: 0, left: 0,
                padding: 6,
                backgroundColor: '#efefef',
                textAlign: 'center',
            }}>
            © 2021 - {new Date().getFullYear()} - АСУЕ Smart Metrix 1.3
        </div>
    </React.Fragment>,
    document.getElementById('root')
);
