import React from 'react';
import { useWatch } from 'react-hook-form';
import { 
    AutocompleteInput, 
    ReferenceInput, 
    required
} from 'react-admin';     
import AddressReferenceInput from '../devices/AddressReferenceInput';

const DeviceModeInput = () => { 
    const device_type_id = useWatch({ name: 'device__type' });  
 
    if (!device_type_id) return null;  
        
    return (
        <>
            <ReferenceInput  
                source="device_mode" 
                reference="device/mode" 
                filter={{device_model: device_type_id}}>
                <AutocompleteInput 
                    optionText="name" 
                    fullWidth 
                    validate={[required()]} 
                    label="Режим порта устройства" />    
            </ReferenceInput>
        </>
    )
};

export default DeviceModeInput;