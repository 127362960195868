import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography'; 
import Label from '@mui/icons-material/Label';  
import IconButton from "@mui/material/IconButton"; 
import Tooltip from "@mui/material/Tooltip";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight'; 
import ActionMenu from './ActionMenu';  
 

const useTreeItemStyles = makeStyles((theme) => ({
 
 
  expanded: {},
  selected: {},
  label: { 
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center', 
  },
  labelIcon: { 
  },
  labelText: {
    flexGrow: 1, 
    display: "flex"
  },
  content: { 
    width: "inherit"
  }, 
  meters_count: {
    marginLeft: "5px"
  }
}));

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const { 
    labelText, labelIcon: LabelIcon, 
    color, bgColor, tree,
    selectedNodeId, setNodeId, ...other } = props; 
  return (
    <TreeItem
      key={props.nodeId}
      nodeId={props.nodeId} 
      label={
        <div className={classes.labelRoot}> 

          <Tooltip title="Фильтр по Узлу">  
              <IconButton 
                  onClick={(event) => {
                     event.stopPropagation();
                      event.preventDefault();
                      selectedNodeId === props.nodeId ? 
                        setNodeId(undefined) :  setNodeId(props.nodeId);
                  }}>
                {selectedNodeId === props.nodeId ? 
                    <RadioButtonCheckedIcon  fontSize='small' /> 
                    : <RadioButtonUncheckedIcon fontSize='small' /> }
              </IconButton>   
          </Tooltip>
          <Typography variant="body2" className={classes.labelText}>
            {labelText} 
          </Typography>
          <Tooltip title="Кол-во измерителей"> 
            <Typography variant="caption" color="inherit" fontWeight="bold" className={classes.meters_count}>
              {tree.meters_count}
            </Typography>
          </Tooltip>
          <Tooltip title="Действия"> 
            <>
              <ActionMenu 
                tree={tree} 
                style={{ marginLeft: 'auto' }}  />
            </>
          </Tooltip>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  children: PropTypes.array,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.object,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: { 
    flexGrow: 5,  
  },
});

 
const MyTree = ({ tree, setNodeId, selectedNodeId }) => {
     
    if (!tree || !tree.id) return null;
    
    return (
        <>
            <StyledTreeItem 
                tree={tree}
                nodeId={String(tree.id)} 
                selectedNodeId={selectedNodeId} 
                setNodeId={setNodeId}  
                labelText={tree.name}  
                labelIcon={Label} >
                {tree.children && tree.children
                  .map(child => 
                    <MyTree 
                      key={child.id} 
                      tree={child} 
                      setNodeId={setNodeId} 
                      selectedNodeId={selectedNodeId}
                      />)}
            </StyledTreeItem>
        </>
    )
}

const NodeTree = ({tree, selectedNodeId, setNodeId}) => {
    const classes = useStyles(); 
    const [nodes, setNodes] = useState(localStorage.nodes && localStorage.nodes.split(",")); 
    const onNodeToggle = (e, nodeIds) => {   
      localStorage.setItem("nodes", nodeIds);
      setNodes(nodeIds);
    }
    return ( 
            <TreeView
                defaultCollapseIcon={<ArrowDropDownIcon />} 
                defaultExpandIcon={<ArrowRightIcon />}
                defaultExpanded={ nodes }
                expanded={ nodes }
                onNodeToggle={ onNodeToggle } 
            > 
                <MyTree 
                  tree={tree} 
                  setNodeId={setNodeId} 
                  key={tree.id} 
                  selectedNodeId={selectedNodeId} />
            </TreeView> 
       
    );
}
export default NodeTree