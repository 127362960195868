import React from 'react';
import { Create } from 'react-admin';  
import { makeStyles } from '@mui/styles'; 
import MeterForm from './MeterForm';

const useStyles = makeStyles((theme) => {
    return ({ 
        raCreate: {
            card: {
                backgroundColor: "#F5F7FA"
            
            },
            noActions: {
                marginTop: "0px"
            },
        }
    })
});


const MeterCreate = props => {
    const classes = useStyles();
    return(
        <Create title={<span>Измерители/Добавить</span>} {...props} classes={classes.raCreate} sx={{marginBottom: "100px"}}>
            <MeterForm />
        </Create>
    )
};

export default MeterCreate;