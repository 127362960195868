import React from 'react'; 
import { 
    FormDataConsumer,
    TextInput,
    NumberInput,
    TextField,
    NumberField,
    required
} from 'react-admin'; 
import { Box } from '@mui/material';
import QuickCreateReferenceInput from '../components/QuickCreateInput';
import YandexMap from './AddressYandexMap';
const Inputs = () => (
    <>
        <FormDataConsumer>
            {({ formData, ...rest }) => ( 
                <YandexMap formData={formData} {...rest}/>
                )
            }
        </FormDataConsumer> 
        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput 
                    source="locality" 
                    label='Город'
                    validate={required()} 
                    /> 
                <TextInput 
                    source="street" 
                    label='Улица'
                    validate={required()} 
                    /> 
                <NumberInput 
                    source="lng" 
                    label='Долгота'
                    validate={required()} 
                    /> 
            </Box>
            <Box
                flex={1}
                mr={{ xs: 0, sm: '0.5em' }}>
                <TextInput 
                    source="district" 
                    label='Городской район' 
                    /> 
                <TextInput 
                    source="house" 
                    label='Дом'
                    validate={required()} 
                    /> 
                <NumberInput 
                    source="lat" 
                    label='Широта'
                    validate={required()} 
                    /> 
            </Box>
        </Box>
    </>

)

const fields = [ 
    <TextField 
        source="locality" 
        label='Город' 
        />,
    <TextField 
        source="district" 
        label='Городской район' 
        />,
    <TextField 
        source="street" 
        label='Улица' 
        />,
    <TextField 
        source="house" 
        label='Дом' 
        />,
    <NumberField 
        source="lng" 
        label='Долгота' 
        />,
    <NumberField
        source="lat" 
        label='Широта'
        /> 
]
 
 

const AddressReferenceInput = props => {
    
    return (
        <QuickCreateReferenceInput
            {...props}
            fullWidth
            label="Адрес" 
            perPage={10}
            optionText={({street, house}) => `${street}, ${house}`} 
            inputs={<Inputs/>}
            fields={fields}/>
    );
};

export default AddressReferenceInput;