import React from 'react';
import {  
    DateField, 
    TextField,
    Show,  
    BooleanField,
    NumberField, 
    ImageField, 
    SimpleShowLayout,
    SelectField
} from 'react-admin';  
import { Grid, Typography } from '@mui/material';

const MeterShow = props => {
    return (
        <Show title=" " {...props}  >   
            <Grid >
                <Grid container  style={{ width: "100%" }} spacing={1} >
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <Typography variant="body2">
                                <b>Узел:</b>
                                &nbsp; 
                                <TextField source="node__name" variant="body1"/> 
                            </Typography> 
                            <Typography variant="body2">
                                <b>Потребитель:</b>
                                &nbsp;
                                <TextField
                                    source="consumer"
                                    variant="body1"
                                />
                            </Typography> 
                            <Typography variant="body2">
                                <b>Лицевой счет/№ договора:</b>
                                &nbsp;
                                <TextField
                                    source="account_id"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Серийный номер:</b>
                                &nbsp;
                                <TextField
                                    source="serial_number"
                                    variant="body1"
                                />
                            </Typography> 
                            <Typography variant="body2">
                                <b>Модель измерителя:</b>
                                &nbsp;
                                <TextField
                                    source="type__name"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Показание при подключении:</b>
                                &nbsp;
                                <TextField
                                    source="join_reqading"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Дата подключения:</b>
                                &nbsp;
                                <TextField
                                    source="join_date"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Место установки:</b>
                                &nbsp;
                                <TextField
                                    source="installation_place__name"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Объект:</b>
                                &nbsp;
                                <TextField
                                    source="object_type__name"
                                    variant="body1"
                                />
                            </Typography>  
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={4}>
                        <SimpleShowLayout>
                            <Typography variant="body2">
                                <b>Устройство СПД:</b>
                                &nbsp;
                                <TextField
                                    source="device__eui"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Режим порта устройства:</b>
                                &nbsp; 
                                <TextField variant="body1" source="device_mode__name" />   
                            </Typography> 
                            <Typography variant="body2">
                                <b>Порт:</b>
                                &nbsp; 
                                <NumberField source="port" variant="body1" options={{ maximumFractionDigits: 0 }}/> 
                            </Typography> 
                            
                            <Typography variant="body2">
                                <b>Клиентский сектор:</b>
                                &nbsp; 
                                <SelectField  
                                    source="client_sector" 
                                    variant="body1" 
                                    choices={[
                                        {
                                            id: 'legal',
                                            name: 'Юридический сектор'
                                        },
                                        {
                                            id: 'private',
                                            name: 'Частный сектор'
                                        },
                                        {
                                            id: 'multi_apartment',
                                            name: 'Многоквартирный сектор'
                                        },
                                        {
                                            id: 'physical',
                                            name: 'Физический сектор'
                                        }
                                    ]} />  
                            </Typography> 
                            <Typography variant="body2">
                                <b>Вкл/Выкл:</b>
                                &nbsp; 
                                <BooleanField source="is_active" variant="body1"/> 
                            </Typography>  
                            <Typography variant="body2">
                                <b>Статус выгрузки данных:</b>
                                &nbsp; 
                                <TextField variant="body1" source="upload_status" />   
                            </Typography> 
                            <Typography variant="body2">
                                <b>Дата выгрузки данных:</b>
                                &nbsp; 
                                <DateField variant="body1" source="upload_date" />   
                            </Typography> 
                        </SimpleShowLayout>
                    </Grid> 
                    <Grid item xs={4}>
                        <ImageField source="avatar" label="Фото" />  
                    </Grid>
                </Grid>
                <SimpleShowLayout>
                    <Typography variant="body2">
                        <b>Описание:</b>
                        &nbsp;  
                        <TextField source="description" variant="body1"/>  
                    </Typography> 
                </SimpleShowLayout>
            </Grid> 
        </Show>
    );
}

export default MeterShow;