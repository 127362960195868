// in ./CustomResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button, 
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    SimpleForm, 
    SaveButton,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar, 
    ReferenceArrayInput,
    AutocompleteArrayInput, 
    DateInput,
    NumberInput
} from 'react-admin'; 
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DownloadIcon from '@mui/icons-material/GetApp';
import IconCancel from '@mui/icons-material/Cancel';

import axios from 'axios';
import { saveAs } from 'file-saver';

import { API_URL } from '../dataProvider';

const InstallPassports = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const [loading, setLoading] = React.useState(false);
    const notify = useNotify();
    const unselectAll = useUnselectAll('device'); 
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === "" || (Array.isArray(data[key]) && data[key].length === 0)) {
                delete data[key];
            }
        });
        const ids = data.device_ids;
        delete data.device_ids; 
        // updateMany(
        //     'device', 
        //     { 
        //         ids: [ids[0]],
        //         data: {action: 'get_passports', ids, date_created: data.date_created, type: data.type}
        //     },
        //     {
        //         onSuccess: (data) => {
        //             console.log("data", data);
        //             saveAs(data, "report");

        //             refresh();
        //             notify('Изменения приняты!');
        //             unselectAll();
        //         },
        //         onFailure: error => notify('Ошибка: изменения не приняты', 'warning'),
        //     }

        // );

        setLoading(true);
        axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
        axios
            .patch(`${API_URL}/api/v1/device/${ids[0]}/`, {...data, ids, action: "get_passports"},{responseType: 'blob'})
            .then(
                ({ data, status, ...props}) => {
                    if (status==200){  

                        // Используйте saveAs для сохранения Blob в файле с указанным именем
                        saveAs(data, "report"); 
                        setLoading(false);
                        unselectAll(); 
                        setOpen(false);
                    }
                }
            )
            .catch(error => { 
                console.error(error);
                notify('ra.notification.http_error', 'warning');
                setLoading(false); 
                setOpen(false);
            }); 


    };

    const DeviceEditToolbar = () => (
        <Toolbar fullWidth>
            <Button
                label="ra.action.cancel"
                onClick={handleDialogClose}
                disabled={loading}
            >
                <IconCancel />
            </Button>
            <SaveButton sx={{ marginLeft: "auto" }}/>
        </Toolbar>
    );

    return (
        <Fragment>
            <Button label="Экспорт паспортов" onClick={handleClick}><DownloadIcon /></Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle>Экспорт паспортов</DialogTitle>
        
                <DialogContent> 
                    <SimpleForm toolbar={<DeviceEditToolbar/>} 
                        sanitizeEmptyValues
                        onSubmit={handleConfirm} 
                        defaultValues={{device_ids: selectedIds}}
                        > 
                        <ReferenceArrayInput  
                            source="device_ids" 
                            reference="device" 
                            >
                            <AutocompleteArrayInput 
                                fullWidth 
                                validate={required()}
                                label="Устройства СПД" 
                                disableClearable
                                clearOnBlur={false}
                                optionText={choice => choice && `${choice.eui} | ${choice.description}`} />
                        </ReferenceArrayInput> 

                        <ReferenceInput  
                            source="type"  
                            reference="device/model" >
                            <AutocompleteInput fullWidth optionText="name" label="Модель устройства" validate={required()}/>    
                        </ReferenceInput>  
                        <NumberInput source='num_of_ports' 
                            label="Количество портов"
                            validate={required()} />
                        <DateInput label="Дата производства" 
                            source="date_created"
                            validate={required()}/>
                    </SimpleForm>
                    
                </DialogContent> 
                <DialogActions>
                   
                </DialogActions>
            </Dialog>
            {/* <Confirm
                isOpen={open}
                loading={isLoading}
                title="Обновить состояние"
                content="Вы действительно хотите обновить состояние для данных устройств?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> */}
        </Fragment>
    );
}

export default InstallPassports;