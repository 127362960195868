import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from 'ra-language-english';
import russianMessages from 'ra-language-russian';



export default polyglotI18nProvider(locale => {
    if (locale === 'ru') {
        return russianMessages;
    }
    // Always fallback on english
    return englishMessages;
}, 'ru');
