import React, { useState } from 'react'; 

import { 
    Button, 
    SimpleShowLayout,  
    useGetOne,
    Loading } from 'react-admin';

import { IconButton, Drawer } from '@mui/material';

import IconImageEye from '@mui/icons-material/RemoveRedEye';
import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
 
const QuickPreviewButton = ({ id, fields, reference }) => {
    const [showPanel, setShowPanel] = useState(false);  
    const { data, isLoading } = useGetOne(reference, { id });

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };
    

    if (isLoading) return <Loading/>;

    return (
        <>
            <IconButton onClick={handleClick} size="small" color="primary">
                <IconImageEye fontSize="small" />
            </IconButton> 
             
            <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
                <div>
                    <Button label="Закрыть" onClick={handleCloseClick}>
                        <IconKeyboardArrowRight />
                    </Button>
                </div>
                <SimpleShowLayout
                    record={data} 
                > 
                    {fields}
                </SimpleShowLayout>
            </Drawer>
        </>
    );
};

export default QuickPreviewButton;
