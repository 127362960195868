import React from 'react'; 
import {
    Datagrid, 
    DateField,  
    SelectField 
} from 'react-admin';   
import { JsonField } from "react-admin-json-view";

import { Card, CardHeader, CardContent } from '../../../components/CustomCard';  
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';

const Expand = (props) => ( 
    <Card>
        <CardContent>
            <JsonField
                {...props}
                source="data"
                addLabel={true}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
                }}
            />
        </CardContent>
    </Card>
)

const ListChild = props => {   
    return(
        <Datagrid {...props} expand={<Expand/>}> 
            <DateField source="dt" label="Дата/Время" showTime/>
            <SelectField source="reading" label="Состояние люка" choices={[
                {
                    id: 0,
                    name: 'Закрыт'
                },
                {
                    id: 1,
                    name: 'Открыт'
                }
            ]}/> 
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}


const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return (   
        <Card>
            <CardHeader title="Состояние люка"/> 
            <CardContent> 
                <CustomChart 
                    key={1}
                    chartData={chartData} 
                    YLabel="Состояние люка"
                    YDataKey="reading"
                    lineColor="black"
                    diffReadingPeriod={diffReadingPeriod}/> 
            </CardContent>
        </Card>     
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)
 

export default DataStorePage;
 

 