import * as React from 'react';
import { Card as MuiCard, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';
const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

const FilterSidebar = ({children, ...props}) => (
    <Card>
        <CardContent>
            {children}
        </CardContent>
    </Card>
);

export default FilterSidebar