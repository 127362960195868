import React from "react";
import {
    ResourceContextProvider,
    List,
    Datagrid,
    DateField,
    TextField,
    BooleanField,  
} from "react-admin";
import { Typography } from "@mui/material";

import { Card, CardContent, CardHeader } from "../../components/CustomCard";


const ReadingCorrectionList = (props) => ( 
    <Card>
        <CardHeader title="Таблица Корректировок"/> 
        <CardContent>
            <ResourceContextProvider value="reading_correction" >
                <List    
                    basePath="reading_correction" 
                    title=" " 
                    filter={{meter: props.meter_id}} 
                    actions={null}
                    empty={<Typography>Корректировок показании нет</Typography>}> 
                    <Datagrid>
                        <DateField source="dt" label="Дата" />
                        <TextField source="data.true_reading" label='Показание'/>  
                        <BooleanField source="data.pulse_counter_isnull" label='Порт обнулен(Кол-во импульсов равно нулью)'/>  
                    </Datagrid>
                </List>
            </ResourceContextProvider>
        </CardContent>
    </Card>  
)

export default ReadingCorrectionList;