import React, { useState } from 'react';
import { 
    List,  
    useRefresh,
    useListContext,
    Loading,
    CustomRoutes
} from 'react-admin'; 
import { useNavigate, Route, Routes} from 'react-router-dom'; 

import { Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NodeTree from './node_tree';
import NodeQuickEditButton from './NodeQuickEditButton';
import YandexMeterLocationMap from './yandex-map';
import { Card, CardContent } from '../components/CustomCard';


const useStyles = makeStyles({
    main: {
        height: '90vh',
        margin:"5px", 
    },
    content: {
        padding: "10px", 
        minHeight: "60vh"
    }
});


 

const Node = (props) => { 
    const classes = useStyles();
    const { data, isLoading } = useListContext();
    if (isLoading) return <Loading/>;
    return (           
        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box> 
                <Card>
                    <CardContent > 
                        <Paper className={classes.content}>
                            {data.
                                map(record => 
                                    <NodeTree 
                                        key={record.id} 
                                        tree={record} 
                                        setNodeId={props.setNodeId} 
                                        selectedNodeId={props.node_id}/>)}
                        </Paper> 
                    </CardContent>
                </Card>
            </Box>
            <Box
                flex={3}
                mr={{ xs: 0, sm: '0.5em' }}
                
                >
                    <Card>
                        <CardContent>
                            <YandexMeterLocationMap filters={{node_id: props.node_id}}/>
                        </CardContent>
                    </Card>
            </Box>
        </Box> 
    )
}
const NodeList = (props) => {   
    const navigate = useNavigate();
    const refresh = useRefresh();
    const [node_id, setNodeId] = useState(null);
    // const [openNodeList, setOpenList] = useState([]);
    const handleClose = () => { 
        navigate(-1);
        setTimeout(refresh, 100);
    } 
    return (
        <Box>
            <List {...props} 
                pagination={null}
                perPage={1000}
                filters={null}
                filter={{parent: 'none'}}
                actions={null} >
                <Node 
                    node_id={node_id} 
                    setNodeId={setNodeId} 
                    />
            </List>
            <Routes>
                <Route path=":id" 
                    element={
                        <NodeQuickEditButton  
                            onClose={handleClose}/> 
                    }
                />

                <Route path="create/:parent_id"
                    element={
                        <NodeQuickEditButton  
                            onClose={handleClose} />}
                    />
                    
                
            </Routes>
        </Box>
);}


export default NodeList