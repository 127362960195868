import React from 'react';
import {   
    TopToolbar,
    FilterButton,
    CreateButton,
    sanitizeListRestProps, 
} from 'react-admin'; 
 
import ExportButton from './ExportButton';
 


const ListActions = (props) => {
    const {
        className,
        maxResults,
        ...rest
    } = props; 
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {/* {cloneElement(props.filters, { context: 'button' })} */}
            <FilterButton/>
            <CreateButton/>
            <ExportButton report="default_report"/>
        </TopToolbar>
);
}

export default ListActions;