import React, { cloneElement, useState, useEffect } from 'react';  
import {
    useListContext,
    Loading, 
} from 'react-admin';
import DataStoreList from '../DataStorePage/DataStoreList';

const DataStoreBase = ({meter_id, dataGrid, children, ...props}) => {
    const [chartData, setChartData] = useState([]);
    const [ids, setIds] = useState([]); 
    const [diffReadingPeriod, setDiffReadingPeriod] = useState('dayly');  
 
    const CustomDataGrid = ({ paginationData:{ page, perPage }, ...props }) => { //временное решение
        const { data, isLoading } = useListContext();
        
        useEffect(() => {  
            
            if (!isLoading &&
                (data.length !== chartData.length || data.map(({id}) => id).join() !== ids)){ //передаю данные в графики; другой способ не нашел
                    setIds(data.map(({id}) => id).join());
                    setChartData(
                        data.map(record => (
                            {
                                ...record,
                                dt: new Date(record.dt).getTime()
                            }
                        ))
                    );
                }
            
        },[data]); 
        if (isLoading) return <Loading />; 
        return (
            cloneElement(dataGrid, {
                ...props, 
                meter_id, 
                data: data.slice((page - 1) * perPage, perPage * page)
            })
        )         
    }   

 
    return ( 
        <div > 
            <DataStoreList meter_id={meter_id} setDiffReadingPeriod={setDiffReadingPeriod}>
                <CustomDataGrid/>
            </DataStoreList>
            {cloneElement(children, {
                diffReadingPeriod,
                chartData
                })}
        </div> 
    )
} 

export default DataStoreBase;