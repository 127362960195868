import * as React from "react";
import {  
    useNotify, 
    useCreate,
    NumberInput,
    BooleanInput, 
    DateTimeInput, 
    Loading,
    ResourceContextProvider,
    SimpleForm,
    Create,
    Toolbar,
    SaveButton
} from 'react-admin'; 
import EditIcon from '@mui/icons-material/Edit';
import IconCancel from '@mui/icons-material/Cancel';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';

const ReadingCorrectionCreate = ({ record, meter_id, onChange }) => {
    const [open, setOpen] = React.useState(false); // Controls modal 

    const [create] = useCreate(); // Access dataProvider API call
    const notify = useNotify(); // Initialize notify object to send notification to dashboard
     
 
    const handleSubmit = async values => {
        create(
            'reading_correction',
            { data: values },
            {
                onSuccess: ({ data }) => {
                    setOpen(false);
                    notify('ra.notification.created', 'info', { smart_count: 1 }); // Default onSuccess function
                    // form.change('id', data.id); // Add the new user to the userId reference input.
                    onChange() // Alert the form that something has changed
                },
                onFailure: ({ error }) => {
                    notify('Something went wrong.', 'error');
                }
            }
        );
    };

    const CustomToolbar = props => (
        <Toolbar {...props}>
            <Button
                label="ra.action.cancel"
                onClick={() => setOpen(false)}
            >
                <IconCancel fontSize="small"/>
            </Button>
            <SaveButton />
        </Toolbar>
    );

    if (!record) return <Loading/>;

    return (
        <> 
            <Button
                variant="body2"
                title="Изменить"
                onClick={() => setOpen(true)}
            >
                <EditIcon fontSize="small" />
            </Button>
     
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Задать корректное показание</DialogTitle>
                <DialogContent>
                    <ResourceContextProvider value="reading_correction">
                        <Create>
                            <SimpleForm
                                onSubmit={handleSubmit}  
                                key={1} // support for refresh button 
                                toolbar={<CustomToolbar/>}
                                defaultValues={{ 
                                    meter: meter_id, 
                                    data_store_id: record.id, 
                                    dt: record.dt, 
                                    pulse_counter_isnull: false,
                                    data: { num_of_pulse: record.num_of_pulse, incorrect_reading: record.reading } }}
                                >   
                                <DateTimeInput 
                                    source="dt"
                                    label="Дата/Время"
                                    fullWidth
                                    showTime
                                    disabled />
                                <NumberInput 
                                    source="data.true_reading"
                                    label="Настоящее показание"
                                    fullWidth/>
                                <BooleanInput 
                                    source="data.pulse_counter_isnull"
                                    label="Порт обнулен" 
                                    fullWidth/> 
                            </SimpleForm> 
                        </Create>
                    </ResourceContextProvider>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ReadingCorrectionCreate;