import * as React from 'react';
import { 
    Card as MuiCard, 
    CardContent as MuiCardContent, 
    CardHeader as MuiCardHeader, 
    CardActions as MuiCardActions } from '@mui/material';

import { Grid } from '@mui/material';
import {makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    card: { 
      margin: "5px", 
      border: '2px solid #DEDEDE', 
      borderRadius: "1px",      
      backgroundColor: theme.palette.background.default
    },
    cardHeader: {  
        margin: "0"
    },
    cardContent: { 
        margin: "3px", 
        border: '2px solid #DEDEDE', 
        borderRadius: "1px",  
        backgroundColor: theme.palette.background.paper
      }, 
    cardActions: {}
  }));

export const Card = ({children, ...props}) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <MuiCard className={classes.card}> 
                {children} 
            </MuiCard>
        </Grid>
);}

export const CardContent = ({children, ...props}) => {
    const classes = useStyles();
    return (
        <MuiCardContent className={classes.cardContent} {...props}>
            {children}
        </MuiCardContent> 
);}

export const CardHeader = ({children, ...props}) => {
    const classes = useStyles();
    return(
        <MuiCardHeader className={classes.cardHeader} {...props}>
            {children}
        </MuiCardHeader> 
);}

export const CardActions = ({children, ...props}) => {
    const classes = useStyles();
    return(
        <MuiCardActions className={classes.cardActions} {...props}>
            {children}
        </MuiCardActions> 
);}


 