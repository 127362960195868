import React from 'react';
import {   
    Edit,   
    Show,  
    useRecordContext
} from 'react-admin';  
import { useTheme } from '@mui/material'; 
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';  
import TabPanel from '../meters/TabPanel';
import GatewayShow from './GatewayShow';   
import DeviceForm from './DeviceForm';


const useStyles = makeStyles((theme) => {
    return ({
        raEdit: {
            card: {
                backgroundColor: "#F5F7FA", 
            
            },
            noActions: {
                marginTop: "0px"
            },
        },
        appBar: {
            width: "100px"
        },
    })
});


const DeviceEdit = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const Title = () => {
        const record = useRecordContext();
        return <span>Устройства/EUI: {record ? `${record.eui}` : ''}</span>;
    };

    const a11yProps = (index) => {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    return ( 
        <div className={classes.root}>
            <AppBar position="static" color="default" className={classes.AppBar}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary" 
                    aria-label="full width tabs example"
                >
                    <Tab label="Покрытие" {...a11yProps(0)} /> 
                    <Tab label="Настройка" {...a11yProps(1)} />
                </Tabs>
            </AppBar> 
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Show title={<Title />} {...props} actions={null} >
                    <GatewayShow />
                </Show>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}  >
                <Edit title={<Title />} {...props} fullWidth actions={null} className={classes.raEdit}>
                    <DeviceForm />
                </Edit>
            </TabPanel> 
        </div>
    );
}

export default DeviceEdit