import React, { useEffect } from 'react';
import { Layout, AppBar, UserMenu } from 'react-admin';
import { Typography, useMediaQuery, Link } from '@mui/material';
import {makeStyles } from '@mui/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import  favicon from './favicon.ico';
import  logo from './logo_name.png';
import path from "./event_sound.mp3";

 

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

 
const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    company_logo: {
        marginLeft: "5px"
    }
    
}));

 

// const ConfigurationMenu = forwardRef(({ onClick }, ref) => (
//     <MenuItemLink
//         ref={ref}
//         to="/configuration"
//         primaryText="Configuration"
//         leftIcon={<SettingsIcon />}
//         onClick={onClick} // close the menu on click
//     />
// ));

// const SwitchLanguage = forwardRef((props, ref) => {
//     const locale = useLocale();
//     const setLocale = useSetLocale();
//     const classes = useStyles();
//     return (
//         <MenuItem
//             ref={ref}
//             className={classes.menuItem}
//             onClick={() => {
//                 setLocale(locale === 'en' ? 'ru' : 'en');
//                 props.onClick();
//             }}
//         >
//             <ListItemIcon className={classes.icon}>
//                 <Language />
//             </ListItemIcon>
//             Switch Language
//         </MenuItem>
//     );
// });

const MyUserMenu = props => (
    <UserMenu {...props}>
        {/* <ConfigurationMenu /> */}
    </UserMenu>
);


const Event = ({data}) => {
    if (!data.eui && !data.serial_number)
        return (
            <Link href='#/event'>
                <div>{data.name}!</div>
                <div>{data.reason}!</div> 
                <div>Узел: {data.node__name}</div> 
            </Link> 
        )
    return (
        <Link href='#/event'>
            <div>{data.name}!</div>
            <div>{data.reason}!</div> 
            <div>Узел: {data.node__name}</div>
            <div>EUI устройства: {data.eui}</div>
            <div>Серийный номер измерителя: {data.serial_number}</div>
            <div>Адрес: {data.address}</div>
        </Link>
    )
}


const EventSnackbar = () => { 
    const { enqueueSnackbar }  = useSnackbar();

    const ws = 'wss://'
        + 'sm.iot-exp.kz'
        + '/wss/events/'
        + `?token=${localStorage.getItem('token')}` 
 
    useEffect(() => { 
        const chatSocket = new WebSocket(ws);
        
        let audio = new Audio(path);
        chatSocket.onopen = (e) => {
            console.log("Соединение с сервером по сокету установлено");
        };
        chatSocket.onmessage = function(e) {
            const data = JSON.parse(e.data);  
            console.log("Message: ", data); 
            audio.play(); 
            enqueueSnackbar(
                <Event data={data} />,  
                { variant: data.type }); 
        };
    
        chatSocket.onclose = function(e) {
            console.error('Chat socket closed unexpectedly');
        };
    }, [])
    return (<span />)
}


const MyAppBar = props => {
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return( 
        <AppBar {...props} 
            userMenu={<MyUserMenu />} >
            <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
                <img src={favicon} height={30}  alt="favicon"/>
                { !isSmall && <img src={logo} height={30} className={classes.company_logo} alt="logo"/> }
                <span className={classes.spacer} />
                <SnackbarProvider maxSnack={5} >
                    <EventSnackbar/>
                </SnackbarProvider> 
        </AppBar>)
};

export default props => <Layout {...props} appBar={MyAppBar} />;
