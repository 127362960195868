import React, { useState, useEffect } from 'react';   
import { useDataProvider, Loading, Error, required } from 'react-admin'; 
import { useWatch } from 'react-hook-form';

import { CustomInput } from '../helpers'; 

const DeviceModeInputs = () => {
    const dataProvider = useDataProvider();
    const [deviceMode, setDeviceMode] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const device_mode = useWatch({ name: "device_mode" }); 
    useEffect(() => { 
        if (!device_mode && deviceMode) {
            setDeviceMode(null);
        }
        else if ( device_mode && !deviceMode || device_mode && device_mode !== deviceMode.id ){
            setLoading(true);
            dataProvider.getOne('device/mode', { id: device_mode })
                .then(({ data }) => {
                    setDeviceMode(data); 
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                })
        }
    }, [device_mode]);


    if (loading) return <Loading />;
    if (error) return <Error />; 
    if (!deviceMode?.additional_data?.fields) return null;
    
    return (
            deviceMode.additional_data.fields.map(({label, name, type}) => (
                <CustomInput  
                    fullWidth
                    key={name}  
                    label={label} 
                    source={name} 
                    custom_input_type={type} 
                    validate={[required()]} 
                    fullwidth/>)
        )
    )
};

export default DeviceModeInputs;