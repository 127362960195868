import React, { useState } from 'react';
import { 
    Datagrid,
    List,   
    DateField,
    TextField,   
    useListContext,
    Pagination,
    BooleanField 
} from 'react-admin'; 

import { makeStyles, useTheme } from '@mui/styles'; 
import { 
    Box, 
    Typography, 
    useMediaQuery, 
    Tab, Tabs, AppBar, 
    AccordionSummary, 
    Accordion, 
    AccordionDetails } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import TabPanel from './TabPanel';
import cold_water from '../icons/cold_water.png';
import hot_water from '../icons/hot_water.png';
import heat from '../icons/heat.png'
import gas from '../icons/gas.png'
import sensor from '../icons/sensor.png'
import hatch from '../icons/hatch.png'
import moisture from '../icons/moisture.png'
 
import { AddressField, LinkField } from '../helpers'; 
import MeterBulkActionButtons from './MeterBulkActionButtons';  
import MeterShow from './MeterShow';
import filters, { FilterSidebar } from './filters';
import ListActions from './ListActions';  
import YandexMeterLocationMap from '../nodes/yandex-map'; 
import { Card, CardContent } from '../components/CustomCard';
import MobileGrid from './MobileGrid';

const useStyles = makeStyles((theme) => {
    return ({
        root: {
            flexGrow: 1, 
            width: "100%",
            backgroundColor: theme.palette.background.paper,
          },
        rootMobile: {
            flexGrow: 1, 
            width: "100vw",
            backgroundColor: theme.palette.background.paper,
          },
        raEdit: {
            card: {
                backgroundColor: "#F5F7FA", 
            
            },
            noActions: {
                marginTop: "0px"
            },
        }, 
        sensors: {
            marginTop: "10px", 
            flexGrow: 1,
        },
        exportButton: {
            
            border: '2px solid #DEDEDE',
        },
        reportTab: { 
            height: "100%",
            alignContent:"center"
        },  
    })
});
  
const resource_type_ids = {
    'cold_water': 1,
    'hot_water': 2,
    'gas': 3,
    'electricity': 4,
    'heat': 5,
    'video': 6,
    'sensor': 7, // default sensor tab
    'hatch_sensor': 7,
    'moisture_sensor': 8,
    'pressure_sensor': 10,
    'all': undefined
}

const getKeyByValue = (object, value) => {
    for (let key in object) {
      if (object[key] === value) {
        return key;
      }
    }; 
  }

const MeterData = props => {
    const [mapState, setMapState] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { filterValues, setFilters, ...listContext } = useListContext();
    const [tabPanel, setTabPanel] = useState(getKeyByValue(resource_type_ids, filterValues.resource_type) || "all");
    const handleChange = (event, newValue) => {
        setTabPanel(newValue);
        setFilters({...filterValues, resource_type: resource_type_ids[newValue]});
    };
 
    const a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
          };
    }

    const DatagridDefault = () => isSmall ? (
            <MobileGrid/>
        ) : ( 
            <Datagrid style={{marginTop: "15px"}} {...props} optimized 
                    rowClick={() => ('edit')} 
                    expand={<MeterShow actions={null}/>}
                    rowStyle={({is_active}) => (!is_active ? {backgroundColor: "#d9d7d7"}: {})}> 
                <TextField source="consumer" label='Потребитель'/> 
                <AddressField source='street' label='Адрес'/>
                <TextField source="apartment" label='Квартира'/> 
                <TextField source="account_id" label='Лицевой счет/№ договора'/> 
                <TextField source="serial_number" label='Серийный номер'/> 
                <LinkField label="Устройство СПД" link='device' source="device" optionText="device__eui"/>  
                <DateField source="sent_date" label="Последнее сообщение" showTime/>
                <TextField source="last_reading" label='Показание'/>  
                <BooleanField source='is_active' label="Вкл/Выкл" />
            </Datagrid>  
        )
       
    return (
        <div className={isSmall ? classes.rootMobile : classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabPanel.includes("sensor") ? "sensor" : tabPanel}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary" 
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="full width tabs example"
                    >
                    <Tab title={ !isSmall && "Все" }
                        value="all"
                        label="Все"
                        {...a11yProps("all")} />

                    <Tab title={ !isSmall && "Холодная вода" } 
                        value="cold_water"
                        icon={<img src={cold_water} style={{width: "25px", height: "25px"}} alt="icon"/>} 
                        {...a11yProps("cold_water")} /> 

                    <Tab title={ !isSmall && "Горячая вода" }
                        value="hot_water"
                        icon={<img src={hot_water} style={{width: "25px", height: "25px"}} alt="icon"/>} 
                        {...a11yProps("hot_water")} />

                    <Tab title={ !isSmall && "Газ" }
                        value="gas"
                        icon={<img src={gas} style={{width: "25px", height: "25px"}} alt="icon"/>} 
                        {...a11yProps("gas")} /> 

                    <Tab title={ !isSmall && "Электричество" }
                        value={"electricity"}
                        icon={<FlashOnIcon 
                        color="primary"/>} 
                        {...a11yProps("electricity")} /> 

                    <Tab title={ !isSmall && "Отопление" }
                        value="heat"
                        icon={<img src={heat} style={{width: "25px", height: "25px"}} alt="icon"/>} 
                        {...a11yProps("heat")} /> 
                        
                    <Tab title={ !isSmall && "Видеонаблюдение" }
                        value="video"
                        icon={<VideocamOutlinedIcon color="primary"/>}  
                        {...a11yProps("video")} />
                        
                    <Tab title={ !isSmall && "Датчики" }
                        value={"sensor"}
                        icon={<img src={sensor} style={{width: "25px", height: "25px"}} alt="icon"/>}  
                        {...a11yProps("sensor")} /> 
                   
                </Tabs>
            </AppBar> 
       
            <TabPanel value={tabPanel} index={"all"} dir={theme.direction}>
                <DatagridDefault />
            </TabPanel>
            <TabPanel value={tabPanel} index={"cold_water"} dir={theme.direction}  >
                <DatagridDefault />
            </TabPanel> 
            <TabPanel value={tabPanel} index={"hot_water"} dir={theme.direction}  >
                <DatagridDefault />
            </TabPanel> 
            <TabPanel value={tabPanel} index={"gas"} dir={theme.direction}  >
                <DatagridDefault />
            </TabPanel> 
            <TabPanel value={tabPanel} index={"electricity"} dir={theme.direction}  > 
                <DatagridDefault />
            </TabPanel>  
            <TabPanel value={tabPanel} index={"heat"} dir={theme.direction}>
                <DatagridDefault />
            </TabPanel>  
            <TabPanel value={tabPanel} index={"video"} dir={theme.direction}>
                <DatagridDefault />
            </TabPanel>
            <TabPanel value={tabPanel && tabPanel.includes("sensor") ? "sensor" : tabPanel} index={"sensor"} dir={theme.direction}  > 
                <Box
                    display="flex"
                    justifyContent="center" 
                    alignItems="center" 
                    m={1}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={tabPanel == 'sensor' ? "hatch_sensor" : tabPanel}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform" 
                        justifyContent="center"
                        alignItems="center"
                        >
                        <ToggleButton value={"hatch_sensor"}>
                            <img src={hatch} style={{width: "25px", height: "25px"}} alt="icon"/>
                            &nbsp; { !isSmall && "Датчик вскрытия" }
                        </ToggleButton>
                        <ToggleButton value={"moisture_sensor"}>
                            <img src={moisture} style={{width: "25px", height: "25px"}} alt="icon"/>
                            &nbsp;{ !isSmall && "Датчик влажности" }
                        </ToggleButton> 
                        <ToggleButton value={"pressure_sensor"}>
                            <TireRepairIcon color="primary"/>
                            &nbsp;{ !isSmall && "Датчик давления" }
                        </ToggleButton> 
                    </ToggleButtonGroup>  
                </Box> 
                <TabPanel value={tabPanel == "sensor" ? "hatch_sensor" : tabPanel} index={"hatch_sensor"}>
                    {isSmall ? (
                        <MobileGrid/>
                    ) : ( 
                        <Datagrid style={{marginTop: "15px"}} {...props} optimized rowClick={() => ('edit')} expand={<MeterShow actions={null}/>}> 
                            <TextField source="consumer" label='Потребитель'/> 
                            <AddressField source='street' label='Адрес'/>
                            <TextField source="account_id" label='Лицевой счет/№ договора'/> 
                            <TextField source="serial_number" label='Серийный номер'/> 
                            <LinkField label="Устройство СПД" link='device' source="device" optionText="device__eui"/>  
                            <DateField source="sent_date" label="Последнее сообщение" showTime/>
                            <TextField source="last_reading" label='Состояние'/>  
                        </Datagrid>  
                    )}
                </TabPanel>
                <TabPanel value={tabPanel} index={"moisture_sensor"}>
                    {isSmall ? (
                        <MobileGrid/>
                    ) : ( 
                        <Datagrid style={{marginTop: "15px"}} {...props} optimized rowClick={() => ('edit')} expand={<MeterShow actions={null}/>}> 
                            <TextField source="consumer" label='Потребитель'/> 
                            <AddressField source='street' label='Адрес'/>
                            <TextField source="account_id" label='Лицевой счет/№ договора'/> 
                            <TextField source="serial_number" label='Серийный номер'/> 
                            <LinkField label="Устройство СПД" link='device' source="device" optionText="device__eui"/>  
                            <DateField source="sent_date" label="Последнее сообщение" showTime/>
                            <TextField source="last_reading" label='Влажность, %'/>  
                        </Datagrid>  
                    )}
                </TabPanel>

                <TabPanel value={tabPanel} index={"pressure_sensor"}>
                    {isSmall ? (
                        <MobileGrid/>
                    ) : ( 
                        <Datagrid style={{marginTop: "15px"}} {...props} optimized rowClick={() => ('edit')} expand={<MeterShow actions={null}/>}> 
                            <TextField source="consumer" label='Потребитель'/> 
                            <AddressField source='street' label='Адрес'/>
                            <TextField source="account_id" label='Лицевой счет/№ договора'/> 
                            <TextField source="serial_number" label='Серийный номер'/> 
                            <LinkField label="Устройство СПД" link='device' source="device" optionText="device__eui"/>  
                            <DateField source="sent_date" label="Последнее сообщение" showTime/>
                            <TextField source="last_reading" label='Давление, бар'/>  
                        </Datagrid>  
                    )}
                </TabPanel>
            </TabPanel>  
            <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 1000]} />  
   
            <Card>
                <Accordion
                    onChange={(f,expand) => setMapState(expand)}
                > 
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        > 
                        <Typography>Карта</Typography> 
                    </AccordionSummary> 
                    <AccordionDetails style={{display: "block"}}>
                        <CardContent> 
                            {mapState && <YandexMeterLocationMap filters={filterValues}/>}
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            </Card> 
        </div>
    );
}


const MeterList = (props) => { 
     
    
    return( 
        <List {...props}
            filters={filters} 
            filterDefaultValues={{ resource_type: 1 }}
            sort={{ field: 'sent_date', order: 'DESC' }}
            aside={<FilterSidebar/>}
            title="Измерители"
            pagination={null}
            actions={<ListActions/>}
            bulkActionButtons={<MeterBulkActionButtons />}>
                <MeterData  />
        </List>  
    )
}

export default MeterList;