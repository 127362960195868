import React, { useState } from 'react'; 
import {  
    List, 
    SimpleList,
    DateField, 
    TextField,   
    useListContext, 
    useGetList, 
    Loading,
    ResourceContextProvider,
    useRecordContext
} from 'react-admin'; 

import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import { Pagination } from 'ra-ui-materialui';

import { JsonField } from "react-admin-json-view";

import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { getDataBySignal, SignalField, MyDatagrid } from "../helpers";
import redDot from "../icons/redDot.png" 
import greenDot from "../icons/greenDot.png"
import yellowDot from "../icons/yellowDot.png"
import orangeDot from "../icons/orangeDot.png"
import greyDot from "../icons/greyDot.png"
import blueDot from "../icons/blueDot.png"

import { Card, CardContent } from '../components/CustomCard';

const dots = { 
    redDot,
    greenDot,
    yellowDot,
    orangeDot,
    greyDot,
    blueDot
}

const useStyles = makeStyles({
 
    mapDiv: { 
        height: '100%',
        width: "60%", 
        margin: "10px"
    },
    map: {  
        height: '60vh',  
        width: "100%"
    },
    root: {  
        width:"100%"
    }
  
  });

const GatewayPlacemarks = (props) => {
    let gateways = JSON.parse(localStorage.getItem("gateways")); 
    if (gateways && new Date() - gateways.time > 10 * 60 * 1000 || gateways.data.length < 2) gateways = null; // 10 minut unterval
    
    const { data, isLoading, error } = useGetList(
        'gateway',
        {
            pagination: { page: 1, perPage: gateways ? 1 : 10000 }, 
        }
    );
    if (isLoading || !data) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }
     
    if (data.length > 1) 
        localStorage.setItem("gateways", JSON.stringify({ data, time: new Date()}));
     
    gateways = JSON.parse(localStorage.getItem("gateways")); 
    if (!gateways?.data) return null
    const gatewayPointsIds = []; 
    const points = props.data.map(record => 
            { 
                let gateway = gateways.data.find(({id}) => id === record.gateway);
                if (!gateway) return null;
                let size = props.zoom; 
                const signalLevel = getDataBySignal(record);  
                gatewayPointsIds.push(gateway.id);
                return (
                    <Placemark 
                        key={gateway.id} 
                        geometry={[gateway.lat, gateway.lng]} 
                        properties={{
                            hintContent: gateway.eui,
                            iconContent: props.zoom > 11 ? 
                                `<div style="margin-left:15px; white-space: nowrap">${record.dt.slice(0, 10)}</div>` : null,
                            balloonContent:  gateway.eui,
                          }}
                        options={{ 
                            iconLayout: 'default#imageWithContent', 
                            iconImageHref: dots[`${signalLevel.color}Dot`],
                            iconImageSize: [size, size], 
                            iconImageOffset: [-size, -size]
                          }}/>)}
        )  
    const { lat, lng } = props.device;
    console.log(gateways.data);
    return points.concat(
                    gateways.data
                        .filter(gateway => !gatewayPointsIds.includes(gateway.id) 
                                    && Math.abs(lat - gateway.lat) < 0.5
                                    && Math.abs(lng - gateway.lng) < 0.5)
                        .map(gateway => {  
                            let size = props.zoom; 
                            return (
                                <Placemark 
                                    key={gateway.id} 
                                    geometry={[gateway.lat, gateway.lng]} 
                                    properties={{
                                        hintContent: gateway.eui,
                                        balloonContent:  gateway.eui,
                                    }}
                                    options={{
                                        iconLayout: 'default#image',
                                        iconImageHref: blueDot,
                                        iconImageSize: [size, size], 
                                        iconImageOffset: [-size, -size]
                                    }}/>)
                        })
    )
}


const YandexGatewayLocationMap = ({device, ...props}) => { 
    const apiKey = "db24f8e9-c6fe-438a-bc18-e4c125364ba7";
    const [zoom, setZoom] = useState(13);
    const classes = useStyles();

    const { data, isLoading } = useListContext(); 
    
    return (  
        <YMaps query={{apikey: apiKey}}>
            <Map className={classes.map} 
                defaultState={{ 
                    center: [ device.lat, device.lng], 
                    zoom
                }} 
                modules={["package.full"]} 
                onBoundsChange={
                    ({_cache: { newZoom }}) => setZoom(newZoom) }> 
                <Placemark 
                    key={device.id} 
                    geometry={[device.lat, device.lng]} 
                    properties={{
                        iconCaption: device.eui,
                    }}
                    options={{preset: 'islands#blueCircleIcon', zIndex: 10000}}/>
                
                {!isLoading && <GatewayPlacemarks data={data} zoom={zoom} device={device} />} 
            </Map> 
        </YMaps>  
    )
}

const Expand = (props) => ( 
        <Card>
            <CardContent>
                <JsonField
                    {...props}
                    source="data"
                    addLabel={true}
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                    }}
                />
            </CardContent>
        </Card>
    )

const CustomPagination = () => {
    const {
        page, perPage, 
        total, 
        setPage, setPerPage
    } = useListContext();

    return <Pagination 
                rowsPerPageOptions={[10, 25, 50, 100]} 
                total={total}
                perPage={perPage}
                page={page}
                setPage={setPage}
                setPerPage={setPerPage}
            />;
}

const Test = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Box fullWidth display={{ md: 'block', lg: 'flex' }}>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}> 
                { isSmall ? ( 
                    <SimpleList
                        primaryText={record => `Шлюз: ${record.gateway_eui}, Описание:${record.gateway__description}`}
                        secondaryText={record => `Сигнал:  RSSI: ${record.rssi} | SNR: ${record.snr} - ${getDataBySignal(record).title}`}
                        linkType={null}
                        tertiaryText={record => `
                            ${new Date(record.dt).toLocaleDateString()}, 
                            ${new Date(record.dt).toLocaleTimeString()}`} 
                        />
                    ) : (
                    <Card>
                        <CardContent>
                            <MyDatagrid 
                                    rowClick="expand" 
                                    expand={<Expand/>} {...props}>  
                                <TextField source="gateway_eui" label='Шлюз'/> 
                                <SignalField label='Качество покрытия'/>   
                                <DateField source="dt" label="Замечен" showTime/> 
                            </MyDatagrid>
                            <CustomPagination/>
                        </CardContent>
                    </Card>)
                }
            </Box>
            <Box flex={2} mr={{ md: 0, lg: '1em' }}> 
                <Card>
                    <CardContent>
                        <YandexGatewayLocationMap device={props.device}/>
                    </CardContent>
                </Card>
            </Box>
        </Box> 
    )
}



const GatewayShow = (props) => {  
    const classes = useStyles();
    const record = useRecordContext();
    if (!record) return null;
    return( 
        <ResourceContextProvider value="data_store_default" >
            <List 
                classes={{ content: classes.root }}
                sort={{ field: 'dt', order: 'DESC' }}
                filter={{ distinct: 'gateway', device: record.id }} 
                basePath="data_store_default"
                actions={null} 
                title=' ' 
                pagination={null}
                >
                <Test device={record}/>
            </List>
        </ResourceContextProvider> 
    )
}

export default GatewayShow;