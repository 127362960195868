import RouterIcon from '@mui/icons-material/Router';  
import DeviceList from './DeviceList'; 
import DeviceEdit from './DeviceEdit';
import DeviceCreate from './DeviceCreate';

export default {
    list: DeviceList,
    create: DeviceCreate,
    edit: DeviceEdit,  
    icon: RouterIcon, 
};