import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { YMaps, Map, Placemark } from 'react-yandex-maps'; 
import axios from "axios"; 
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    input: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        width: '100%'
    },
    yandexMap: {
        width: '100%',
        height: '50vh'
    }

});


const YandexMap = props => {
    const apiKey = "db24f8e9-c6fe-438a-bc18-e4c125364ba7";
    const classes = useStyles(); 
    // const [ymaps, setYMaps] = useState();
    const form = useFormContext();   
    const [defaulCoords, setDefaultCoords] = useState(undefined);
    const addressBranchList = ['locality', 'provice', 'area', 'district', 'street', 'house'];
    const yandexParseSearchResults = (GeocoderMetaData, coords) => {
        const [lat, lng] = coords; 
        form.setValue('lat', lat);
        form.setValue('lng', lng); 
        const components = GeocoderMetaData.Address.Components;
        addressBranchList.forEach(branch => form.setValue(branch, undefined))
        components.forEach(({kind, name}) => {
            if (addressBranchList.includes(kind))
                form.setValue(kind, name);
        });

    };
    const yandexGeoCodingByCoords = (lat, lng) => {
         
        axios.get(
            `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=${apiKey}&geocode=` + lng + "," + lat 
        )
            .then(response => {
                const result = response.data.response.GeoObjectCollection.featureMember[0].GeoObject;
                
                yandexParseSearchResults(result.metaDataProperty.GeocoderMetaData, [lat, lng]);
            })
            .catch(error => {
                console.log("Ошибке при попытке геокодинга", error);
            });
    }
    
    if (defaulCoords === undefined){
        navigator
            .geolocation
            .getCurrentPosition(({coords: {latitude, longitude}}) => {
                setDefaultCoords([latitude, longitude]);
                yandexGeoCodingByCoords(latitude, longitude);
            });
        if (defaulCoords === undefined){
            setDefaultCoords([43.238949, 76.889709]);
        }
    } 
    
    return (
        <YMaps query={{ apikey: apiKey}} >
            <Map  className={classes.yandexMap}
                defaultState={{ center: defaulCoords, 
                                zoom: 13
                }}
                
                modules={["package.full"]} 
                onClick={(e) => {
                        const [lat, lng] = e.get("coords");   
                        yandexGeoCodingByCoords(lat, lng);
                    }}
                instanceRef={inst => { 
                    if (!inst) return null;  
                    const searchControl = inst.controls.get('searchControl')
                    searchControl.events.add('resultselect', (e) => {
                        const result = searchControl.getResultsArray()[e.get('index')];
                        yandexParseSearchResults(result.properties._data.metaDataProperty.GeocoderMetaData, result.geometry.getCoordinates());
                    });
                    searchControl.options.set('noPlacemark', true)
                    return inst;
                }}

                >   
                    {form.getValues().lat && form.getValues().lng &&
                        <Placemark 
                            geometry={[ form.getValues().lat, form.getValues().lng ]}
                            key={1} />}
            </Map>
        </YMaps>)

}


export default YandexMap;
