// in ./CustomResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    SimpleForm,
    TextInput,
    SaveButton,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar, 
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectInput,
    useDataProvider,
    useCreate,
    DateInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import UpdateIcon from '@mui/icons-material/Update'; 
import EditIcon from '@mui/icons-material/Edit';  
import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';

import AddressReferenceInput from '../devices/AddressReferenceInput';

import { makeStyles } from '@mui/styles'; 
import NodeReferenceInput from '../nodes/NodeReferenceInput'; 
import DeviceModeInput from './DeviceModeInput';
import DeviceModeInputs from '../meters/DeviceModeInputs'; 
import NodeInputs from '../meters/NodeInputs';
import QuickCreateInput from '../components/QuickCreateInput';

import filters, { RelevantFilter } from './filters';
import FilterSidebar from '../FilterSidbar';

const useStyles = makeStyles((theme) => {
  
    return (
        { 
        box: {
            border: '2px solid #DEDEDE',
            borderRadius: '5px',
            backgroundColor: 'white', 
            padding: "10px",
            margin: "5px",
        }
    })
});


const CreateMeters = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('meter_installation');
    const dataProvider = useDataProvider();
    const [create, { isLoading, error }] = useCreate();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const classes = useStyles();
    const handleConfirm = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === "" || (Array.isArray(data[key]) && data[key].length === 0)) {
                delete data[key];
            }
        });
        const ids = data.installations_ids;
        delete data.installations_ids; 
        const values = data;
        ids.forEach((id, idx) => {
            dataProvider.getOne('meter_installation', { id })
                .then(({ data }) => { 

                    create(
                        'meter',
                        { 
                            data: {...data, ...values, installation: id, id: undefined}
                        },
                        {
                            onSuccess: () => {
                                refresh();
                                notify(`${idx+1} из ${values.length()} добавлены!`);
                                unselectAll();
                            },
                            onFailure: error => notify('Ошибка: изменения не приняты', 'warning'),
                        }
            
                    );
                })
                .catch(error => {
                      
                })
        })
        unselectAll();
        setOpen(false);
    };

    const DeviceEditToolbar = () => (
        <Toolbar fullWidth>
            <Button
                label="ra.action.cancel"
                onClick={handleDialogClose}
                disabled={isLoading}
            >
                <IconCancel />
            </Button>
            <SaveButton sx={{ marginLeft: "auto" }}/>
        </Toolbar>
    );

    return (
        <Fragment>
            <Button label="Создать Измерители" onClick={handleClick}><IconContentAdd /></Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle>Создать Измерители</DialogTitle>
        
                <DialogContent> 
                    <SimpleForm toolbar={<DeviceEditToolbar/>} 
                        onSubmit={handleConfirm} 
                        defaultValues={{installations_ids: selectedIds}}> 
                        <ReferenceArrayInput  
                            source="installations_ids" 
                            reference="meter_installation" 
                            >
                            <AutocompleteArrayInput 
                                fullWidth 
                                validate={required()}
                                disableClearable
                                clearOnBlur={false}
                                label="Установленные счетчики" 
                                optionText={choice => choice && `${choice.serial_number} | ${choice.port} | ${choice.description}`} />
                        </ReferenceArrayInput> 

                         
                            <Box width="100%">
                                <Box className={classes.box}>
                                    <Typography variant="h6" gutterBottom>
                                        Измеритель
                                    </Typography>
                                    <NodeReferenceInput 
                                        label="Узел"
                                        source="node"
                                        reference="node" 
                                        perPage={10}
                                        inputs={
                                            <>
                                                <TextInput 
                                                    fullWidth
                                                    source="name" 
                                                    label='Имя'
                                                    validate={[required()]} 
                                                    />  
                                                <QuickCreateInput  
                                                    label="Тип узла" 
                                                    source="type" 
                                                    reference="node/type" 
                                                    suggestionLimit={30}
                                                    validate={[required()]} 
                                                    inputs={[<TextInput source='name' label="Название"/>]}
                                                    /> 
                                                <ReferenceInput 
                                                    label="Родитель" 
                                                    source="parent" 
                                                    reference="node"  
                                                    validate={[required()]} 
                                                    >
                                                    <AutocompleteInput fullWidth optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`}/>     
                                                </ReferenceInput> 
                                                <TextInput 
                                                    fullWidth
                                                    label="Номер телефона" 
                                                    source="phone"  />
                                                <TextInput 
                                                    fullWidth
                                                    source="account_id" 
                                                    label='Лицевой счет/№ договора' 
                                                    /> 
                                                <ReferenceArrayInput 
                                                    label="Пользователи" 
                                                    source="users" 
                                                    reference="user" 
                                                    >
                                                    <AutocompleteArrayInput fullWidth optionText="username" />
                                                </ReferenceArrayInput>
                                                <TextInput 
                                                    fullWidth
                                                    source="description" 
                                                    label="Описание" 
                                                    /> 
                                            </>
                                        }
                                        validate={[required()]} 
                                    /> 
                                    <Box display={{ xs: 'block', sm: 'flex' }} >
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >  
                                            <DateInput 
                                                fullWidth source="join_date" 
                                                label="Дата подключения"
                                                validate={[required()]} /> 
                                            <NodeInputs /> 
                                        </Box>

                                        <Box
                                            flex={1}
                                            ml={{ xs: 0, sm: '0.5em' }}
                                        >    
                                            <QuickCreateInput 
                                                fullWidth 
                                                label="Место установки" 
                                                source="installation_place"
                                                reference="installation_place"
                                                perPage={30}
                                                validate={[required()]} 
                                                inputs={
                                                    <TextInput source="name" label="Название" />
                                                }/> 
                                        </Box>
                                    </Box> 
                                </Box> 
                                <Box className={classes.box}>
                                    <Typography variant="h6" gutterBottom>
                                        Устройство СПД
                                    </Typography>
                                    <AddressReferenceInput 
                                        source="device__address" 
                                        reference="address"
                                        validate={required()}
                                        /> 
                                    <ReferenceInput  
                                        source="device__type" 
                                        reference="device/model" 
                                        validate={required()} >
                                        <AutocompleteInput fullWidth optionText="name" label="Модель устройства" />    
                                    </ReferenceInput> 
                                    <DeviceModeInput />   
                                    {/* <DeviceModeInputs /> */}
                                </Box> 
                                <Box className={classes.box}>
                                    <Typography variant="h6" gutterBottom>
                                        Потребитель
                                    </Typography> 
                            
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >                                      
                                            <SelectInput  
                                                source="client_sector" 
                                                label='Клиентский сектор'  
                                                choices={[
                                                    {
                                                        id: 'legal',
                                                        name: 'Юридический сектор'
                                                    },
                                                    {
                                                        id: 'private',
                                                        name: 'Частный сектор'
                                                    },
                                                    {
                                                        id: 'multi_apartment',
                                                        name: 'Многоквартирный сектор'
                                                    },
                                                    {
                                                        id: 'physical',
                                                        name: 'Физический сектор'
                                                    }
                                                ]}
                                                validate={[required()]} 
                                                fullWidth/>    
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        >  
                                            <QuickCreateInput 
                                                fullWidth
                                                label="Тип объекта" 
                                                source="object_type" 
                                                reference="object_type" 
                                                validate={[required()]}
                                                inputs={
                                                    <TextInput source="name" label="Название" />
                                                }/>  
                                        </Box> 
                                    </Box>
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                          
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        > 
                                            <TextInput 
                                                source="account_id" 
                                                label='Лицевой счет/№ договора' 
                                                fullWidth/> 
                                        </Box>
                                        <Box
                                            flex={1}
                                            mr={{ xs: 0, sm: '0.5em' }}
                                        > 
                                            <TextInput 
                                                source="phone" 
                                                label='Номер телефона' 
                                                fullWidth/> 
                                        </Box> 
                                    </Box>
                                </Box>
                            </Box>  
                                 
                         
                    </SimpleForm>
                    
                </DialogContent> 
                <DialogActions>
                   
                </DialogActions>
            </Dialog>
            {/* <Confirm
                isOpen={open}
                loading={isLoading}
                title="Обновить состояние"
                content="Вы действительно хотите обновить состояние для данных устройств?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> */}
        </Fragment>
    );
}

export default CreateMeters;