import * as React from 'react';
 
import { 
    FilterList, 
    FilterListItem, 
    SearchInput, 
    AutocompleteInput,
    ReferenceInput, 
} from 'react-admin';  

import AccessTimeIcon from '@mui/icons-material/AccessTime';
 

export const RelevantFilter = () => (
    <FilterList label="Актуальность" icon={<AccessTimeIcon />}>
        <FilterListItem
            label="Да"
            value={{ 
                relevant: true
            }}
        />
        <FilterListItem
            label="Нет"
            value={{ 
                relevant: false
            }}
        /> 
    </FilterList>
);

const filters = [
    <SearchInput source="q" alwaysOn />,  
    <ReferenceInput source="type" reference="event_type">
        <AutocompleteInput optionText="name" label="Тип события"/>
    </ReferenceInput>
    ]

export default filters;