import React from 'react'; 
import {
    Datagrid, 
    DateField, 
    TextField, 
    useRecordContext,
    useRefresh,  
} from 'react-admin';   
import { JsonField } from "react-admin-json-view";

import ReadingCorrectionCreate from '../../ReadingCorrectionCreate';
import { Card, CardHeader, CardContent } from '../../../components/CustomCard'; 
import ReadingCorrectionList from '../ReadingCorrectionList';
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';


const Expand = (props) => ( 
    <Card>
        <CardContent>
            <JsonField
                {...props}
                source="data"
                addLabel={true}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
                }}
            />
        </CardContent>
    </Card>
)

const ListChild = (props) => {      
    const refresh = useRefresh();  
    const EditReadingButton = ({ record }) => { 
        return (
            <ReadingCorrectionCreate   
                meter_id={props.meter_id}
                record={record}
                onChange={refresh}/>
        ) 
    }  

    const ReadingField = (props) => {
        const record = useRecordContext(props); 
        return(
            <span>
                {record?.reading}  
                <EditReadingButton record={record}  />
            </span>
        );
    }

    return(
        <Datagrid {...props} expand={<Expand />}> 
            <DateField source="dt" label="Дата/Время" showTime/>
            <ReadingField label="Показание"/>
            <TextField source="diff_reading" label="Потребление"/>  
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}



const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return ( 
        <>  
            <ReadingCorrectionList meter_id={meter_id}/> 
            <Card>
                <CardHeader title="Покaзание/Потрeбление"/> 
                <CardContent> 
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="Показание, м3"
                        YDataKey="reading"
                        lineColor="black"
                        diffReadingPeriod={diffReadingPeriod}/>

                    <CustomChart 
                        key={2}
                        chartData={chartData} 
                        YLabel="Потребление, м3"
                        YDataKey="diff_reading"
                        lineColor="red"
                        diffReadingPeriod={diffReadingPeriod}/> 
                </CardContent>
            </Card>    
        </> 
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)
 

export default DataStorePage;