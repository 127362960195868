import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { 
    ReferenceInput, 
    AutocompleteInput,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField 
} from 'react-admin';
import { makeStyles } from '@mui/styles';

import QuickPreviewButton from '../components/QuickCreateInput/QuickPreviewButton';
import QuickCreateButton from '../components/QuickCreateInput/QuickCreateButton';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});
 

const NodeReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const { getValues } = useFormContext();
    const handleChange = useCallback(() => setVersion(version + 1), [version]);
    const id = getValues()?.node; 
    return (
        <div className={classes.root}>
             
                <ReferenceInput key={version} {...props}>
                    <AutocompleteInput 
                        fullWidth
                        validate={props.validate}
                        label={props.label}
                        optionText={
                            choice => choice?.parent_name ? `${choice.parent_name} -> ${choice.name}` : choice.name} />
                </ReferenceInput>
          

            <QuickCreateButton {...props} onChange={handleChange} />
                {!!id  && 
                    <QuickPreviewButton 
                            reference={props.reference} 
                            id={id} 
                            fields={
                                [
                                    <TextField disabled source="id" />,
                                    <TextField source="name" label='Имя'/> ,
                                    <TextField source="account_id" label='Лицевой счет/№ договора'/>,
                                    <TextField source="description" label="Описание"/>,
                                    <ReferenceField label="Тип узла" source="type" reference="node/type">
                                        <TextField source="name"/>    
                                    </ReferenceField>,
                                    <TextField label="Номер телефона" source="phone" className={classes.field} />,
                                    <ReferenceArrayField label="Пользователь" source="user" reference="user">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>,
                                    <ReferenceField label="Родитель" source="parent" reference="node">
                                        <TextField source="name"/>    
                                    </ReferenceField>
                                ] 
                            } 
                            />
                }
        </div>
    );
};

export default NodeReferenceInput;