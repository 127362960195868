import React from 'react'; 
import {
    Datagrid, 
    DateField,  
    NumberField,
    BooleanField,
    SelectField,
    SimpleShowLayout
} from 'react-admin';   
import { JsonField } from "react-admin-json-view";

import { Card, CardHeader, CardContent } from '../../../components/CustomCard';  
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';

const Expand = (props) => {
    return(
        <div>
            <SimpleShowLayout> 
                <BooleanField {...props} source="LowBatteryFlag" label=""/> 
                <SelectField {...props} 
                    source="LastResetReason" 
                    choices={[
                        {
                            id: 0,
                            name: 'сброса не было',
                        },
                        {
                            id: 1,
                            name: 'lockup',
                        },
                        {
                            id: 2,
                            name: 'watchdog',
                        },
                        {
                            id: 3,
                            name: 'power',
                        }
                    ]} 
                    label="Причина последнего сброса микроконтроллера модуля LoRaWAN"/> 
                <BooleanField {...props} 
                    source="DepassivationFlag" 
                    label="Флаг выполненной депассивации встроенного элемента питания"/> 
                <NumberField {...props} 
                    source="UpTime" 
                    label="Общее количество часов работы"/> 
                <NumberField {...props} 
                    source="ErrorTime" 
                    label="Часы наработки устройства с ошибкой"/> 
            </SimpleShowLayout>
   
                <JsonField
                    {...props}
                    label="Пакет"
                    source="data"
                    addLabel={true}
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                    }}
                /> 
        </div>
    )
}

const ListChild = props => {   
    return(
        <Datagrid {...props} expand={<Expand/>}> 
            <DateField source="dt" label="Дата/Время" showTime/>
            <NumberField source="Energy" label="Общая потребленная энергия, Гкал "/> 
            <NumberField source="diff_reading" label="Расход энергии, Гкал"/> 
            <NumberField source="WaterValue" label="Общая потребленная вода, m3"/>  
            <NumberField source="TempIn" label="Температура подачи, °C"/> 
            <NumberField source="TempOut" label="Температура обратки, °C"/> 
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}


const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return ( 
        <div>
            <Card>
                <CardHeader title="Потребленная энергия"/> 
                <CardContent> 
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="Общая, Гкал"
                        YDataKey="Energy"
                        lineColor="red"
                        diffReadingPeriod={diffReadingPeriod}/> 
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="Расход, Гкал"
                        YDataKey="diff_reading"
                        lineColor="black"
                        diffReadingPeriod={diffReadingPeriod}/> 
                </CardContent>
            </Card>

            <Card>
                <CardHeader title="Общая потребленная вода"/> 
                <CardContent> 
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="m3"
                        YDataKey="WaterValue"
                        lineColor="blue"
                        diffReadingPeriod={diffReadingPeriod}/>  
                </CardContent>
            </Card>

            <Card>
                <CardHeader title="Температура"/> 
                <CardContent> 
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="Подачи, °C"
                        YDataKey="TempIn"
                        lineColor="black"
                        diffReadingPeriod={diffReadingPeriod}/>  
                    <CustomChart 
                        key={1}
                        chartData={chartData} 
                        YLabel="Обратки, °C"
                        YDataKey="TempOut"
                        lineColor="black"
                        diffReadingPeriod={diffReadingPeriod}/>   
                </CardContent>
            </Card>     
        </div>  
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)
 

export default DataStorePage;
 

 