import React from 'react'; 
import {
    Datagrid, 
    DateField, 
    TextField,  
} from 'react-admin';   

import { 
    TableContainer, 
    Table, TableBody, 
    TableRow, TableCell,
    Paper
} from '@mui/material';

import { Card, CardHeader, CardContent } from '../../../components/CustomCard';  
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';

const Expand = (props) => { 
    const keys = [
        ["ts","Время передачи в формате Unix"], 
        ["id","Серийный номер модуля"], 
        ["gid","Номер группы, к которой принадлежит устройство"], 
        ["temp","Температура внутри модуля"], 
        ["rssi","Уровень сигнала, принимаемого модулем"], 
        ["snr","Отношение сишнал/шум в модуле"], 
        ["umcu","Напряжение питания платы управления, В"], 
        ["urf","Напряжение питания модуля Nbiot, В"], 
        ["erc","количество перезагрузок радиомодема"], 
        ["P","Текущая потребляемая мощность, Вт"], 
        ["W","Потребление энергии, Вт*ч"], 
        ["alt","Флаг превышения температуры относительно заданного порога"], 
        ["alv","Флаг пропадания напряжения питания"], 
        ["alp","Флаг несоответствия потребляемой мощности с результатами калибровки"], 
        ["tz","Часовой пояс устройства"], 
        ["tlev","Порог температуры, при которой сработает тревога"], 
        ["dmin","Задержка передачи относительно начала часа в минутах"], 
        ["pwm","Установленный уровень ШИМ для ручного режима работы"], 
        ["m","Режим работы устройства \"D\" - не задан, \"M\" - ручной, \"P\" - работа по программе"]
    ]
    return (  
        <Card >
            <CardHeader title="Все Данные"/>
            <CardContent>  
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                    
                        <TableBody>
                            {keys.map(([key, description]) => (

                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">{description}</TableCell> 
                                    <TableCell component="th" scope="row">{key}</TableCell>
                                    <TableCell component="th" scope="row">{props.record.data[key]}</TableCell> 
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> 
            </CardContent>
        </Card> 
    )
}


const ListChild = props => {    
    return(
        <Datagrid {...props}  
                rowClick="expand" 
                expand={<Expand/>} > 
            <DateField source="dt" label="Дата/Время" showTime/>
            <TextField source="reading" label="Мощность, Вт"/> 
            <TextField source="consumed_energy" label="Потребленная энергия, Вт*ч"/>  
            <TextField source="temp" label="Температура, °C"/> 
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}

const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return (   
        <Card>
            <CardHeader title="Таблица энергозатрат"/> 
            <CardContent> 
                <CustomChart 
                    key={1}
                    chartData={chartData} 
                    YLabel="Потребленная энергия, Вт*ч"
                    YDataKey="consumed_energy"
                    lineColor="black"
                    diffReadingPeriod={diffReadingPeriod}/> 
                <CustomChart 
                    key={2}
                    chartData={chartData} 
                    YLabel="Мощность, Вт"
                    YDataKey="reading"
                    lineColor="red"
                    diffReadingPeriod={diffReadingPeriod}/> 
            </CardContent>
        </Card>     
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)
 

export default DataStorePage;