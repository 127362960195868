import React from 'react'; 
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, 
    Label,
    ResponsiveContainer
  } from 'recharts';




const CustomChart = ({ chartData, YLabel, YDataKey, lineColor, diffReadingPeriod, ...props}) => {


    const dateFormatter = date => {
        const dt = new Date(date); 
        switch(diffReadingPeriod){
            case 'hourly':
                return `${dt.getMonth()}.${dt.getDate()}|${dt.getHours()}`
            case 'dayly':
                return `${dt.getMonth()}.${dt.getDate()}`
            case 'monthly':
                return dt.toLocaleString().substring(0, 10)
            default:
                return dt.toLocaleString().substring(0, 10);
        }
    };
    
    return (
        <ResponsiveContainer height={200} >
            <LineChart 
                height={200}
                data={chartData}
                syncId="anyId"
                margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 0,
                }} 
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="dt" 
                    domain={chartData && chartData[0] && [chartData[0].dt, chartData[chartData.length - 1].dt]}
                    scale="time"
                    type="number"
                    angle={-45}
                    tickFormatter={dateFormatter}> 
                        <Label 
                            position="right"
                            style={{ textAnchor: "middle" }}
                            >
                            t
                        </Label>
                </XAxis>
                <YAxis> 
                    <Label
                        angle={270}
                        position="left"
                        style={{ textAnchor: "middle" }}
                        >
                        {YLabel}
                    </Label>
                </YAxis>
                <Tooltip labelFormatter={(date) => new Date(date).toLocaleString()} />
                <Line type="monotone" dataKey={YDataKey} name={YLabel} stroke={lineColor} fill={lineColor} />
            </LineChart>
        </ResponsiveContainer> 
    )
}

export default CustomChart;