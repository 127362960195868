import React from 'react';
 
import {  
    SelectInput, 
    DateInput, 
    required, 
    SimpleForm,  
    Toolbar,
    Button,
    useListContext, 
    useNotify,
    Loading
} from 'react-admin'; 

import { useFormState, useWatch } from 'react-hook-form';

import DownloadIcon from '@mui/icons-material/GetApp'; 
import { Typography, AccordionSummary, Accordion, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from 'axios';
import { saveAs } from 'file-saver';

import { API_URL } from '../dataProvider';

const DynamicInputs = (props) => {
    const reports = {
        report_by_period: [
            <DateInput 
                fullWidth
                key="date_after"
                source="date_after" 
                label="С" 
                validate={required()}
                alwaysOn/>,
            <DateInput 
                fullWidth
                key="date_before" 
                source="date_before" 
                label="По" 
                validate={required()}
                alwaysOn/>
        ],
        report_by_date: [
            <DateInput 
                fullWidth
                key="date"
                source="date" 
                label="Дата" 
                validate={required()}
                alwaysOn/>
        ],
        inactive_meters_report: [
            <DateInput 
                fullWidth
                key="date"
                source="date" 
                label="Нет данных с" 
                validate={required()}
                alwaysOn/>
        ],
        report_current_state: []
    };
    const report = useWatch({name: "report"});
    if (!report) return null;
    return reports[report];
}



const ReportInstaller = (props) => { 
    const {
        filterValues,
        filter,
        resource,
        sort,
        total
    } = useListContext(props);  
    const notify = useNotify(); 
    const [loading, setLoading] = React.useState(false);
    var intervalId;
    const checkTask = (task_id) => { 
        axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
        axios
            .get(`${API_URL}/api/v1/downloads/${task_id}`, {responseType: 'blob'})
            .then(
                ({ data, status, ...props}) => {
                    if (status==202){
                        
                    }
                    else {
                        setLoading(false);
                        clearInterval(intervalId);

                        // Используйте saveAs для сохранения Blob в файле с указанным именем
                        saveAs(data, "report");


                    }
                }
            )
            .catch(error => {
                setLoading(false);
                clearInterval(intervalId);
                console.error(error);
                notify('ra.notification.http_error', 'warning');
            }); 
    }
    const handleSave = (reportFilterValues) => {  
        const filterValues_ = {...filterValues, search: filterValues.q};
        const reportFilterValues_ = {...reportFilterValues};
        Object.keys(filterValues_).forEach(key => filterValues_[key] === undefined && delete filterValues_[key]);
        Object.keys(reportFilterValues_).forEach(key => reportFilterValues_[key] === undefined && delete reportFilterValues_[key]); 
        axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
        axios
            .get(`${API_URL}/api/v1/${resource}/?`
                    + `${new URLSearchParams(filterValues_).toString()}&`
                    + `${new URLSearchParams(reportFilterValues_).toString()}&`
                    + `${new URLSearchParams(filter).toString()}&`
                    + `ordering=${sort.order === 'ASC' ? "-": ""}${sort.field}&`
                    + `page=1&page_size=${10000}&`
                )
            .then(
                ({ data }) => {
                    if (data.task_id){
                        setLoading(true);
                        intervalId = setInterval(function() {
                            // Вызываем функцию с передачей аргументов
                            checkTask(data.task_id);
                        }, 1000);

                        // Устанавливаем таймер на остановку через 5 минут (300 000 миллисекунд)
                        setTimeout(function() {
                            // Очищаем интервал после пяти минут
                            clearInterval(intervalId);
                            setLoading(false);
                            console.log("Прошло пять минут. Интервал остановлен.");
                        }, 300000);
                    } 
                }
            )
            .catch(error => {
                setLoading(false);
                clearInterval(intervalId);
                console.error(error);
                notify('ra.notification.http_error', 'warning');
            });
    }; 
    const MyToolbar = props => {
        const { isValid } = useFormState();
        console.log("loading", loading); 
        if(loading) return <Loading/>
        return (
            <Toolbar {...props}>  
                <Button
                    type="submit"
                    label="Экспорт" 
                    disabled={total === 0}
                >
                    <DownloadIcon/>
                </Button>
            </Toolbar>
        );
    };

    
    return (   
        <Accordion> 
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                > 
                <Typography>Генератор отчетов</Typography> 
            </AccordionSummary>  
            <AccordionDetails> 
                <SimpleForm onSubmit={handleSave} toolbar={<MyToolbar/>}>
                    <SelectInput  
                        source='report' 
                        label="Тип отчета" 
                        alwaysOn 
                        fullWidth
                        choices={[
                            { id: 'report_by_period', name: 'Отчет потребления за период' },
                            { id: 'inactive_meters_report', name: 'Отчет о неактивных Измерителях' },
                            { id: 'report_by_date', name: 'Отчет на дату' },
                            { id: 'report_current_state', name: 'Отчет о текущих состояниях Измерителей' },
                        ]} 
                        validate={required()}/> 
                    <SelectInput  
                        source='template' 
                        label="Шаблон" 
                        alwaysOn 
                        fullWidth
                        validate={required()}
                        choices={[
                            { id: 'default', name: 'По умолчании' },
                            { id: 'kar_su', name: 'Караганды Су' },
                            { id: 'shym_su', name: 'Водные ресурсы - Маркетинг' },
                            { id: 'astana_su', name: 'Астана Су Арнасы' },
                        ]} /> 
                    <DynamicInputs/>   
                </SimpleForm> 
            </AccordionDetails>  
        </Accordion>   
    )
}


export default ReportInstaller;