// in ./CustomResetViewsButton.js
import * as React from 'react';
import { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    SimpleForm,
    TextInput,
    SaveButton,
    ReferenceInput,
    AutocompleteInput,
    required,
    Toolbar, 
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectInput
} from 'react-admin';

import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import UpdateIcon from '@mui/icons-material/Update'; 
import EditIcon from '@mui/icons-material/Edit';  
import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';

import AddressReferenceInput from './AddressReferenceInput';  
const timezones = [
    '+0', '+1', '+2', '+3', '+4', '+5', '+6', '+7', '+8', '+9', '+10', '+11', '+12',
    '-1', '-2', '-3', '-5', '-6', '-7', '-8', '-9', '-10', '-11', '-12'
]

const UpdateDevices = ({ selectedIds }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('device');
    const [updateMany, { isLoading }] = useUpdateMany();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = (data) => {
        Object.keys(data).forEach(key => {
            if (data[key] === "" || (Array.isArray(data[key]) && data[key].length === 0)) {
                delete data[key];
            }
        });
        const ids = data.device_ids;
        delete data.device_ids; 
        updateMany(
            'device', 
            { 
                ids,
                data
            },
            {
                onSuccess: () => {
                    refresh();
                    notify('Изменения приняты!');
                    unselectAll();
                },
                onFailure: error => notify('Ошибка: изменения не приняты', 'warning'),
            }

        );
        setOpen(false);
    };

    const DeviceEditToolbar = () => (
        <Toolbar fullWidth>
            <Button
                label="ra.action.cancel"
                onClick={handleDialogClose}
                disabled={isLoading}
            >
                <IconCancel />
            </Button>
            <SaveButton sx={{ marginLeft: "auto" }}/>
        </Toolbar>
    );

    return (
        <Fragment>
            <Button label="Изменить" onClick={handleClick}><EditIcon /></Button>
            <Dialog
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleDialogClose}
            >
                <DialogTitle>Изменить</DialogTitle>
        
                <DialogContent> 
                    <SimpleForm toolbar={<DeviceEditToolbar/>} 
                        onSubmit={handleConfirm} 
                        defaultValues={{device_ids: selectedIds}}> 
                        <ReferenceArrayInput  
                            source="device_ids" 
                            reference="device" 
                            >
                            <AutocompleteArrayInput 
                                fullWidth 
                                validate={required()}
                                disableClearable
                                clearOnBlur={false}
                                label="Устройства СПД" 
                                optionText={choice => choice && `${choice.eui} | ${choice.description}`} />
                        </ReferenceArrayInput> 

                        <ReferenceInput  
                            source="type" 
                            reference="device/model" >
                            <AutocompleteInput fullWidth optionText="name" label="Модель устройства" />    
                        </ReferenceInput> 
                        <ReferenceInput  
                            source="network_server" 
                            reference="network_server"
                            perPage={3}
                            sort={{ field: 'id', order: 'DESC' }}
                            validate={required()} >
                            <AutocompleteInput fullWidth optionText="name" label="Сетевой сервер" />    
                        </ReferenceInput> 

                        <AddressReferenceInput source="address" reference="address"/> 
                        <ReferenceArrayInput  
                            source="node_service_company" 
                            reference="service_company" 
                            fullWidth
                            >
                                <AutocompleteArrayInput 
                                    fullWidth 
                                    label="Сервисная компания" 
                                    optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`} />
                        </ReferenceArrayInput> 
                        <SelectInput 
                            fullWidth
                            source="timezone" 
                            choices={timezones.map(i => ({ id: parseInt(i), name: i}))}
                            initialValue={(new Date()).getTimezoneOffset()/-60}/>
                        <TextInput source="description" label="Описание" fullWidth/>
                    </SimpleForm>
                    
                </DialogContent> 
                <DialogActions>
                   
                </DialogActions>
            </Dialog>
            {/* <Confirm
                isOpen={open}
                loading={isLoading}
                title="Обновить состояние"
                content="Вы действительно хотите обновить состояние для данных устройств?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> */}
        </Fragment>
    );
}

export default UpdateDevices;