import * as React from 'react';

import { 
    FilterList, FilterListItem,
    DateInput, 
    SelectInput, 
} from 'react-admin'; 

import {
    endOfYesterday, 
    startOfMonth,
    subMonths,
    endOfToday
} from 'date-fns'; 

import AccessTimeIcon from '@mui/icons-material/AccessTime';  

const getDate = (dateTime) => dateTime.toISOString().substring(0, 10);
const daysAgo = (days) => getDate(new Date(Date.now() - days * 24 * 60 * 60 * 1000));
export const DateTimeFilter = () => (
    <FilterList label="Данные за " icon={<AccessTimeIcon />}>
        <FilterListItem
            key={1}
            label="Сегодня"
            value={{
                dt__date__gte: getDate(endOfToday()),
                dt__date__lt: undefined,
            }}
        /> 
        <FilterListItem
            key={1}
            label="Вчера"
            value={{
                dt__date__gte: getDate(endOfYesterday()),
                dt__date__lt: getDate(endOfToday()),
            }}
        /> 
        <FilterListItem
            key={2}
            label="Неделя"
            value={{
                dt__date__gte: daysAgo(6),
                dt__date__lt: undefined,
            }}
        />
        <FilterListItem
            key={3}
            label="Текущий месяц"
            value={{
                dt__date__gte: getDate(startOfMonth(new Date())),
                dt__date__lt: undefined,
            }}
        />
        <FilterListItem
            key={4}
            label="Прошлый месяц"
            value={{
                dt__date__gte: getDate(subMonths(startOfMonth(new Date()),1)),
                dt__date__lt: getDate(startOfMonth(new Date())),
            }}
        /> 
    </FilterList>
);

export const getDataStoreFilters = (setDiffReadingPeriod) => [ 
    <DateInput 
        source="dt__date__gte" 
        alwaysOn  
        label="С" 
        fullWidth
    />,
    <DateInput source="dt__date__lt" 
        alwaysOn  
        label="До"
        fullWidth />,
    <SelectInput 
        source="diff_reading_period" 
        alwaysOn  
        fullWidth
        label="Потребление"
        onChange={(e)=> setDiffReadingPeriod(e.target.value)}
        choices={[
            { id: 'hourly', name: 'Часовое' },
            { id: 'dayly', name: 'Суточное' },
            { id: 'monthly', name: 'Месячное' },
        ]} 
    />   
]