import React from 'react';
import { Fragment } from 'react';
import UpdateReading from './UpdateReading';
import UpdateMeters from './UpdateMeters';
import { BulkDeleteButton } from 'react-admin'; 
 
const MeterBulkActionButtons = props => (
    <Fragment>
        <UpdateMeters {...props} />
        <UpdateReading {...props} /> 
        <BulkDeleteButton {...props} />
    </Fragment>
);
export default MeterBulkActionButtons