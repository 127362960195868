import * as React from 'react';
import {
    endOfYesterday,
    startOfWeek,
    startOfMonth,
    subMonths,
} from 'date-fns';
import { 
    FilterList, 
    FilterListItem,
    List, 
    ResourceContextProvider,
    useListContext,
    SearchInput,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    DateInput,
    DateTimeInput,
    Loading,
    useStore,
    BooleanInput
} from 'react-admin'; 
import moment from "moment"; 

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { makeStyles, withStyles } from '@mui/styles'; 
import { Card as MuiCard, CardContent, Box, IconButton } from '@mui/material';
 
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import NodeTree from '../nodes/node_tree';  
import ReportInstaller from './ReportInstaller.js';

const useStyles = makeStyles(theme => ({
    main: { 
    },
    content: { 
        boxShadow: 'none',
        backgroundColor: 'inherit',
    },
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1, // display on the left rather than on the right of the list 
            marginRight: '0',
            resize:"horizontal"
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));




const Card = withStyles(theme => ({
    root: {
       
    },
}))(MuiCard);




export const FilterSidebar = (props) => {
    const classes = useStyles();
    const [checked, setChecked] = React.useState(true); 
    const [node_id, setNodeIdToStore] = useStore('node_id', undefined);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    const {
        filterValues, // a dictionary of filter values, e.g. { title: 'lorem', nationality: 'fr' }
        setFilters, // a callback to update the filters, e.g. setFilters(filters, displayedFilters)
        displayedFilters, // a dictionary of the displayed filters, e.g. { title: true, nationality: true } 
    } = useListContext();
     
    return(
        <Box display={{ xs: 'block', sm: 'flex' }} className={classes.root}>
            <Box >
                <Card style={{ display: checked ? "block" : "none" }}>
                    <CardContent>
                        <ResourceContextProvider value="node">
                            <List 
                                disableSyncWithLocation
                                basePath="/node" 
                                filters={null}
                                actions={null}
                                pagination={null}
                                perPage={1000}
                                title=" "
                                filter={{parent: 'none'}} 
                                classes={{ main: classes.main }}>
                                    <Node node_id={filterValues.node_id} setNodeId={(node_id) => {
                                        if (node_id !== filterValues.node_id){
                                            setNodeIdToStore(node_id);
                                            setFilters({ 
                                                ...filterValues,
                                                node_id
                                            }, displayedFilters);
                                            
                                        }
                                    }}/> 
                            </List> 
                        </ResourceContextProvider>
                        {/* <Filter {...props}>
                            <ReferenceInput label="Узел" source="node_id" reference="node" alwaysOn>
                                <AutocompleteInput optionText={choice => choice && `${choice.parent_name}->${choice.name}`} />
                            </ReferenceInput> 
                        </Filter> */}
                        <SentDateFilter/>
                        <ActiveFilter />
                        <ReportInstaller/>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <IconButton aria-label="arrow" size="small"
                        style={{height: "30vh" }} 
                        onClick={handleChange}>  
                    { checked ? <ArrowBackIosIcon fontSize="inherit" /> :
                    <ArrowForwardIosIcon fontSize="inherit" />}
                    
                </IconButton>
            </Box>
        </Box> 
);}


const Node = (props) => {  
    const { data, isLoading, error } = useListContext();
    if (isLoading) return <Loading/>
    if (error) return <p>{error}</p>

    return (
        <> 
            {data.map(record => 
                <NodeTree 
                    key={record.id} 
                    tree={record}
                    setNodeId={props.setNodeId} 
                    selectedNodeId={props.node_id}/>)}
        </>
    )
}


const toString = (date) =>  moment(date).format('YYYY-MM-DD HH:MM');

export const SentDateFilter = () => (
    <FilterList label="Выход на связь" icon={<AccessTimeIcon />}>
        <FilterListItem
            label="Сегодня"
            value={{
                sent_date__gte: toString(endOfYesterday()),
                sent_date__lte: undefined,
                sent_date__isnull: undefined
            }}
        />
        <FilterListItem
            label="На этой неделе"
            value={{
                sent_date__gte: toString(startOfWeek(new Date())),
                sent_date__lte: undefined,
                sent_date__isnull: undefined
            }}
        /> 
        <FilterListItem
            label="В этом месяце"
            value={{
                sent_date__gte: toString(startOfMonth(new Date())),
                sent_date__lte: undefined,
                sent_date__isnull: undefined
            }}
        />
        <FilterListItem
            label="В прошлом месяце"
            value={{
                sent_date__gte: toString(subMonths(startOfMonth(new Date()),1)),
                sent_date__lte: toString(startOfMonth(new Date())),
                sent_date__isnull: undefined
            }}
        /> 
        <FilterListItem
            label="Никогда"
            value={{
                sent_date__gte: undefined,
                sent_date__lte: undefined,
                sent_date__isnull: true
            }}
        /> 
    </FilterList>
);

const ActiveFilter = () => (
    <FilterList label="Вкл/Выкл" icon={<FileDownloadDoneIcon />}>
        <FilterListItem
            label="Включен"
            value={{
                is_active: true
            }}
        />
        <FilterListItem
            label="Выключен"
            value={{
                is_active: false
            }}
        /> 
    </FilterList>    
)

const filters = [
    <SearchInput source="q" alwaysOn />,  
    <SelectInput  
        source="client_sector" 
        label='Клиентский сектор'  
        choices={[
            {
                id: 'legal',
                name: 'Юридический сектор'
            },
            {
                id: 'private',
                name: 'Частный сектор'
            },
            {
                id: 'multi_apartment',
                name: 'Многоквартирный сектор'
            },
            {
                id: 'physical',
                name: 'Физический сектор'
            }
        ]}  />,
    <ReferenceInput 
        source="type" 
        label="Модель измерителя" 
        reference="meter%2fmodel">
        <AutocompleteInput optionText="name" label="Модель измерителя" />
    </ReferenceInput>,
    <ReferenceInput 
        source="installation_place" 
        label="Место установки" 
        reference="installation_place">
        <AutocompleteInput optionText="name" label="Место установки" />
    </ReferenceInput>,
    <ReferenceInput source="object_type" reference="object_type" label="Объект">
        <AutocompleteInput optionText="name" label="Объект" />
    </ReferenceInput>,
    <DateInput 
        source="join_date" 
        label="Дата подключения" />,
    <DateTimeInput 
        source="sent_date__gte" 
        label="Дата выхода на связь c" />,
    <DateTimeInput 
        source="sent_date__lte" 
        label="Дата выхода на связь до" />, 
    <DateTimeInput 
        source="sent_date__exact" 
        label="Дата выхода" />]

export default filters;