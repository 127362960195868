import * as React from 'react';
import { Box, Typography } from '@mui/material';
import {
    DateField, 
    NumberField,
    TextField, 
    useListContext, 
    RecordContextProvider,
    Loading
} from 'react-admin';

import { AddressField, LinkField } from '../helpers';

import { Card, CardHeader, CardContent } from '../components/CustomCard';

 

const MobileGrid = (props) => {
    const { data, isLoading } = useListContext(); 
    if (isLoading) {
        return <Loading/>;
    }  
    return (
        <Box >
            {data.map(record => ( 
                <RecordContextProvider key={record.id} value={record} >
                    <Card>
                        <CardHeader
                            title={
                                <Typography variant="h6"> 
                                    <b>Серийный номер:</b>
                                    &nbsp;
                                    <LinkField  
                                        record={record}
                                        link='meter' 
                                        source="id" 
                                        optionText="serial_number" 
                                        variant="body1"/> 
                                </Typography>
                            }
                            titleTypographyProps={{ variant: 'body1' }}
                            action={null}
                        />
                        <CardContent> 
                            <Typography variant="body2">
                                <b>Потребитель:</b>
                                &nbsp;
                                <TextField
                                    source="consumer"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>Лицевой счет/№ договора:</b>
                                &nbsp;
                                <TextField
                                    source="account_id"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <b>Адрес:</b>
                                &nbsp;
                                <AddressField source='street'/>
                            </Typography>
                            <Typography variant="body2">
                                <b>Устройство СПД:</b>
                                &nbsp;
                                <LinkField record={record} link='device' source="device" optionText="device__eui"/>  
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <b>Последнее сообщение:</b>
                                &nbsp;
                                <DateField source="sent_date" showTime />
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <b>Показание:</b>
                                &nbsp;
                                <NumberField
                                    source="last_reading" />
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider> 
            ))}
        </Box>
    );
};

 
export default MobileGrid;