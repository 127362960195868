import React from 'react';
import { Fragment } from 'react';
import UpdateReading from './UpdateReading';
import UpdateDevices from './UpdateDevices';
import InstallPassports from './InstallPassports';
import { BulkDeleteButton } from 'react-admin'; 
 
const DeviceBulkActionButtons = props => (
    <Fragment>
        <UpdateDevices {...props} />
        <InstallPassports {...props} />
        <UpdateReading {...props} /> 
        <BulkDeleteButton {...props} />
    </Fragment>
);
export default DeviceBulkActionButtons