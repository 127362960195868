import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { 
    Button,
    SaveButton, 
    useCreate,
    useNotify, 
    SimpleForm,
    Toolbar,
    ResourceContextProvider,
    Create

} from 'react-admin';

import {  
    IconButton,
    Dialog, 
    DialogContent,
    DialogTitle
} from '@mui/material'; 

import IconContentAdd from '@mui/icons-material/Add';
import IconCancel from '@mui/icons-material/Cancel';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    input: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        width: '100%'
    }
});


const QuickCreateButton = ({ onChange, source, reference, inputs, label }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [create] = useCreate();
    const notify = useNotify();
    const { setValue } = useFormContext(); 
    const classes = useStyles();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        
        create(
            reference,
            { data: values },
            {
                onSuccess: ({id}) => {
                    setShowDialog(false);
                    // Update the comment form to target the newly created meter_model
                    // Updating the ReferenceInput value will force it to reload the available meter_model
                    setValue(source, id);  
                    onChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    }; 
    const CustomToolbar = props => (
        <Toolbar {...props} className={classes.toolbar}>
            <Button
                label="ra.action.cancel"
                onClick={handleCloseClick}
            >
                <IconCancel fontSize="small"/>
            </Button>
            <SaveButton />
        </Toolbar>
    );
    return (
        <>
            <IconButton onClick={handleClick} size="small" color="primary">
                <IconContentAdd />
            </IconButton>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick} 
            >
                <DialogTitle>{`Добавить ${label}`}</DialogTitle>
                <DialogContent> 
                    <ResourceContextProvider value={reference} >
                        <Create>
                            <SimpleForm onSubmit={handleSubmit}  toolbar={<CustomToolbar/>}>
                                {inputs}
                            </SimpleForm>  
                        </Create>
                    </ResourceContextProvider>
                </DialogContent> 
            </Dialog>
        </>
    );
}

export default QuickCreateButton;
