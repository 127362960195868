import React from 'react';
import {  
    Create,  
} from 'react-admin';   
import DeviceForm from './DeviceForm';
 

const DeviceCreate = (props) => { 
    return (
        <Create title="Добавление устройства" {...props}>
            <DeviceForm />
        </Create>
    );
}

export default DeviceCreate;