import React from 'react';
import { 
    AutocompleteInput, 
    AutocompleteArrayInput,
    Datagrid,
    List, 
    SimpleList,
    DateField,  
    TextField,
    NumberField, 
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayField, 
    SelectInput, 
    Filter,
    SearchInput, 
    SimpleShowLayout,
    Pagination,
    ReferenceArrayField, 
} from 'react-admin';   
   
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SignalField, getDataBySignal, BatteryLevelField } from '../helpers'; 
import DeviceLocationMap from './DeviceLocationMap';
import { Card, CardContent, CardHeader } from '../components/CustomCard';
import DeviceBulkActionButtons from './DeviceBulkActionButtons';

const useStyles = makeStyles(() => {
    return ({
        expand: { 
            width: "fit-content",
            // marginRight: "auto",
            // marginLeft: "auto"
          }}
    )
});


const DeviceFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput 
            source="type" 
            label="Модель устройства"
            reference="device/model"
            sort={{ field: 'device_count', order: 'ASC' }}
        >
            <AutocompleteInput optionText="name" label="Модель устройства" />    
        </ReferenceInput> 
        <ReferenceArrayInput 
            source="node_service_company" 
            label="Сервисная компания" 
            reference="service_company">
                <AutocompleteArrayInput 
                    optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`} 
                    fullWidth
                    label="Сервисная компания" />
        </ReferenceArrayInput>
        <ReferenceInput 
            source="gateway" 
            label="Шлюз"  
            reference="gateway">
            <AutocompleteInput 
                style={{minWidth: "250px"}}
                // fullWidth={true}
                source="eui" 
                optionText="eui" 
                label="Шлюз" />    
        </ReferenceInput> 
        <ReferenceInput 
            source="network_server" 
            label="Сетевой сервер"  
            reference="network_server">
            <AutocompleteInput 
                style={{minWidth: "250px"}} 
                optionText="name" 
                label="Сетевой сервер" />    
        </ReferenceInput> 
    </Filter>
);
 

const Expand = (props) => {
    const classes = useStyles();
    if (!props.record) return null;
    return (  
        <div className={classes.expand}>
            <Grid >
                <Grid container  style={{ width: "100%" }} spacing={1} >
                    <Grid item xs={12}>
                        <SimpleShowLayout>
                            <Typography variant="body2">
                                <b>Модель УСПД:</b>
                                &nbsp;
                                <TextField
                                    source="type__name"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>EUI:</b>
                                &nbsp; 
                                <TextField source="eui" variant="body1"/> 
                            </Typography> 
                            <Typography variant="body2">
                                <b>Сервисная компания:</b>
                                &nbsp; 
                                <ReferenceArrayField 
                                    label="Сервисная компания" 
                                    source="node_service_company" 
                                    reference="service_company" />
                            </Typography> 

                            <Typography variant="body2">
                                <b>AppKey:</b>
                                &nbsp;
                                <TextField
                                    source="additional_data.app_key"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>AppsKey:</b>
                                &nbsp;
                                <TextField
                                    source="additional_data.apps_key"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>NwksKey:</b>
                                &nbsp;
                                <TextField
                                    source="additional_data.nwks_key"
                                    variant="body1"
                                />
                            </Typography>
                            <Typography variant="body2">
                                <b>DevAddr:</b>
                                &nbsp;
                                <TextField
                                    source="additional_data.dev_addr"
                                    variant="body1"
                                />
                            </Typography>  
                        </SimpleShowLayout>
                    </Grid>
                   
                </Grid>
                <SimpleShowLayout>
                    <Typography variant="body2">
                        <b>Описание:</b>
                        &nbsp;  
                        <TextField source="description" variant="body1"/>  
                    </Typography> 
                </SimpleShowLayout>
            </Grid> 
            <Card>
                <CardHeader title="Измерители"/>
                <CardContent>
                    <ArrayField  {...props} source="meters" label="Измерители">
                        <Datagrid rowClick={id => `/meter/${id}`} bulkActionButtons={null}>
                            <NumberField source="port" label="Порт"/>
                            <TextField source="serial_number" label="Серийный номер" />
                            <TextField source="consumer" label="Потребитель"/> 
                            <TextField source="account_id" label="№ договора/Лицевой счет"/>
                            <NumberField source="last_reading" label="Показание"/>
                        </Datagrid>
                    </ArrayField>   
                </CardContent>
            </Card>
        </div>
    )
};


const DeviceList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const MyPagination = props => {
        return (
            <div>
                <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} /> 
                <DeviceLocationMap /> 
            </div>
            );
        }
    return (
        <List {...props}
            filters={<DeviceFilter/>}
            sort={{ field: 'sent_date', order: 'DESC' }}
            pagination={<MyPagination />}
            bulkActionButtons={<DeviceBulkActionButtons />}
            >
            { isSmall ? ( 
                <SimpleList
                    primaryText={record => `EUI: ${record.eui}, Адрес:${record.address_name}`}
                    secondaryText={record => `Сигнал: ${getDataBySignal(record).title}`}
                    linkType={record => "edit"}
                    tertiaryText={record => `
                        ${new Date(record.sent_date).toLocaleDateString()}, 
                        ${new Date(record.sent_date).toLocaleTimeString()}`} 
                    />
            ) : (
                <Datagrid  
                    expand={<Expand/>}
                    // isRowExpandable={row => row.meters && row.meters.length > 0}
                    rowClick={() => ('show')}> 
                    <TextField source="eui" label='EUI'/>       
                    {/* <DateField source="date_joined" label="Последнее соединение" showTime/> */}
                    <DateField source="sent_date" label="Последнее сообщение" showTime/>
                    {/* <TextField label="Модель устройства" source="type__name" />  */}
                    <TextField label="Адрес" source="address_name" /> 
                    <TextField label="Описание" source="description" /> 
                    <SignalField label="Качество покрытия" /> 
                    <TextField source="gateway__eui" label='Шлюз'/>       
                    <BatteryLevelField sortBy="battery_level" label="Бат, %" />
                </Datagrid>
            )}
        </List>
    );
}

export default DeviceList;