import React from 'react';

import { useGetList, Loading } from 'react-admin';  
import { useParams } from 'react-router-dom';

import DataStorePageByDeviceMode from './DeviceModes';

const DataStorePage = (props) => {
    const params = useParams();
    const { data, isLoading, error } = useGetList(
        'device/mode',
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'DESC' },
            filter: {meters__id: params.id}
        }
    );
    if (isLoading) { return <Loading />; }
    if (error) { return <p>ERROR</p>; } 
    const DeviceModePage = DataStorePageByDeviceMode[data[0].class_name];

    const DefaultPage = DataStorePageByDeviceMode.DataStorePageDefault;
    return DeviceModePage ? (<DeviceModePage meter_id={params.id} />) : (<DefaultPage meter_id={params.id} />)
    
}
export default DataStorePage;