import SpeedIcon from '@mui/icons-material/Speed'; 
import MeterCreate from './MeterCreate';
import MeterShow from './MeterShow';
import MeterEdit from './MeterEdit';
import MeterList from './MeterList'; 

export default {
    list: MeterList,
    create: MeterCreate,
    edit: MeterEdit, 
    show: MeterShow,
    icon: SpeedIcon
};
