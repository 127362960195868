import React from 'react';
import { 
    Datagrid,
    List,
    SimpleList, 
    SimpleForm,
    Create, 
    Edit,  
    TextField,
    NumberField,
    TextInput,  
    NumberInput,
    SelectInput, 
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    SaveButton, 
    Toolbar,
    useNotify,
    BooleanField,
    Pagination
} from 'react-admin'; 

import { Box, useMediaQuery } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
import { useFormContext } from 'react-hook-form';

import InstallationBulkActionButtons from './InstallationBulkActionButtons';

import filters, { RelevantFilter } from './filters';
import FilterSidebar from '../FilterSidbar';

const MeterInstallationCreateToolbar = () => {
    const notify = useNotify();
    const { reset, resetField, setValue } = useFormContext();

    return (
        <Toolbar>
            <SaveButton
                
                label="Сохранить и добавить другой объект"
                variant="text"
                mutationOptions={{
                    onSuccess: () => {
                        resetField("serial_number");
                        resetField("port");
                        resetField("join_reading");
                        window.scrollTo(0, 0);
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 },
                        });
                    },
                }}
                type="button"
                variant='text'
            />
            <SaveButton sx={{marginLeft: "10px"}}/>
        </Toolbar>
    );
};


 

export const MeterInstallationList = (props) => { 
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return(
        <List {...props} bulkActionButtons={<InstallationBulkActionButtons/>} 
            filters={filters}
            pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]} />}
            aside={<FilterSidebar><RelevantFilter/></FilterSidebar>}
            >{

            isSmall ? (
                <SimpleList
                    primaryText={record => `Серийный номер: ${record.serial_number}, УСПД: ${record.device__eui}, порт: ${record.port}`}
                    secondaryText={record => `Потребитель: ${record.consumer}, Квартира: ${record.apartment}`}
                    linkType={record => "edit"} 
                    />
            ) : (
                <Datagrid rowClick={() => ('edit')}>
                    <TextField source="serial_number" label="Серийный номер Измерителя" />  
                    <TextField source="device__eui" label="УСПД"/>
                    <TextField source="port" label="Порт" />
                    <TextField source="consumer" label="Потребитель"/> 
                    <TextField source="apartment"  label="Квартира" />
                    <TextField source="description" label="Описание" />
                    <TextField source="resource_type" label="Тип ресурса" />
                    <NumberField source="join_reading" label="Начальное показание" />
                    <BooleanField source='meter_exist' label="Измеритель добавлен"/>
                </Datagrid>
            )
        }
        </List>
    );
}

const MeterInstallationTitle = ({ record }) => {
    return <span>Монтаж Измерителя/{record ? `${record.username}` : ''}</span>;
};

const MeterInstallationForm = props => {
 
    return (
            <React.Fragment>
                <ReferenceInput   
                    source="type" 
                    reference="meter%2fmodel" >
                    <AutocompleteInput 
                        fullWidth  
                        optionText={({name, proportionality_factor}) => (`${name}, k: ${proportionality_factor}`)}
                        label="Модель Измерителя" 
                        validate={[required()]}/>
                </ReferenceInput> 
                <TextInput source="serial_number" label="Серийн номер Измерителя" fullWidth validate={[required()]}/>  
                <NumberInput source="join_reading" label="Начальное показание" fullWidth validate={[required()]}/>
                <ReferenceInput 
                    source="device" 
                    reference="device">
                    <AutocompleteInput 
                        fullWidth  
                        optionText={({eui, description}) => (`${eui} | ${description}`)} 
                        validate={[required()]}
                        matchSuggestion={() => true} 
                        label="Устройство СПД"/>    
                </ReferenceInput>
                <NumberInput source="port" label="Порт" fullWidth validate={[required()]}/>
                <TextInput source="consumer" label="Потребитель" fullWidth/> 
                <TextInput source="apartment"  label="Квартира" fullWidth/>
                <TextInput source="description" label="Описание" fullWidth/>
                <ReferenceInput   
                    source="resource_type" 
                    reference="resource_type">
                    <SelectInput 
                        fullWidth 
                        optionText="name" 
                        label="Тип ресурса" 
                        validate={[required()]}/>
                </ReferenceInput>  
            </React.Fragment>
    )
}

const MeterInstallationCreate = (props) => { 
    return (
    <Create title={<span>Монтаж Измерителя/Добавить</span>} name="Монтаж Измерителя" {...props} sx={{marginBottom: "100px"}}>
        <SimpleForm sanitizeEmptyValues {...props} toolbar={<MeterInstallationCreateToolbar/>} >
            <MeterInstallationForm />
        </SimpleForm>
    </Create>
);}

const MeterInstallationEdit = (props) => (
    <Edit title={<MeterInstallationTitle/>} name={<MeterInstallationTitle />} {...props} sx={{marginBottom: "100px"}}>
        <SimpleForm sanitizeEmptyValues {...props} >
            <MeterInstallationForm />
        </SimpleForm>
    </Edit>
);





export default {
    list: MeterInstallationList,
    create: MeterInstallationCreate,
    edit: MeterInstallationEdit, 
    icon: HandymanIcon,
};
