import React, { useState, useEffect } from 'react'; 
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';
import { 
    Datagrid,
    List, 
    DateField, 
    TextField,  
    Show,
    SimpleShowLayout,
    ReferenceField,  
    useListContext,
    Pagination,
    SimpleList, 
} from 'react-admin'; 

import EventIcon from '@mui/icons-material/Event';   
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material'; 
import { LinkField } from "../helpers";
import filters, { RelevantFilter } from './filters';
import FilterSidebar from '../FilterSidbar';
import { Card, CardContent } from '../components/CustomCard';

const useStyles = makeStyles({
 
    yandexMap: { 
        height: '75vh'
    }
  
  });

const YandexEventLocationMap = () => {
    const apiKey = "db24f8e9-c6fe-438a-bc18-e4c125364ba7";
    const [map, setMap] = useState();
    const [mapSettings, setMapSettings] = useState({
      zoom: 5,
      coords:{ 
        lat: 48.11, 
        lng: 66.22}, 
    });
    const classes = useStyles();

    const { data } = useListContext(); 

    useEffect(() => { 
        if (data?.length && map){
            let {lat, lng} = data[0]; 
            map.setCenter([lat, lng], 9);}  
     }, [data, map]) 

     

    return ( 
            <YMaps  query={{apikey: apiKey}}>
                <Map className={classes.yandexMap}
                    instanceRef={ ref => setMap(ref) }
                    defaultState={{ center: [ mapSettings.coords.lat, mapSettings.coords.lng], 
                                    zoom: mapSettings.zoom
                    }} 
                    modules={["package.full"]}
                    onBoundsChange={
                      ({_cache:{newCenter, newZoom, newBounds}}) => {
                          setMapSettings(
                            {
                              zoom: newZoom, 
                              coords:{ 
                                lat: newCenter[0], 
                                lng: newCenter[1]
                              },
                              bounds: `[[${newBounds[0]}],[${newBounds[1]}]]`
                            }); 
                        }
                      }
                >
                    <Clusterer
                        modules={["clusterer.addon.balloon"]}
                        options={{}}
                        
                    >
                        {data?.map(record => 
                                <Placemark 
                                key={record.id} 
                                geometry={[record.lat, record.lng]} 
                                properties={{
                                    iconCaption: record.meter__serial_number,
                                    balloonContentBody: [
                                        '<div>',
                                          `<h3>Событие: ${record.name}</h3>`,  
                                            `<div>
                                              <div>Причина: ${record.reason}</div>
                                              <div><a href="#/meter/${record.meter}">Серийный номер: ${record.meter__serial_number}</a></div>
                                              <div>EUI: ${record.device__eui}</div>
                                              <div>Дата выхода: ${record.dt}</div>
                                            </div>`
                                          ,
                                        '</div>'
                                    ].join('')
                                }}
                                options={{preset: 'islands#darkOrangeAttentionCircleIcon'}}/>)}
                    </Clusterer>
                
                </Map>
            </YMaps> 
    )
}



const MyPagination = props => { 
    return (
        <div>
            <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
            <Card>
                <CardContent>
                    <YandexEventLocationMap />
                </CardContent>
            </Card>
        </div>
        );
    }



 


const EventlList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props}
            filters={filters}
            aside={<FilterSidebar><RelevantFilter/></FilterSidebar>}
            pagination={<MyPagination/>}
            sort={{ field: 'dt', order: 'DESC' }}>
            { isSmall ? ( 
                <SimpleList 
                    primaryText={record => `Название: ${record.name}, Причина:${record.reason}`}
                    secondaryText={(record) => `Устройство СПД: ${record.device__eui}, Измеритель: ${record.meter__serial_number}`}
                    tertiaryText={record => `
                        ${new Date(record.dt).toLocaleDateString()}, 
                        ${new Date(record.dt).toLocaleTimeString()}`} 
                    />
            ) : (
                <Datagrid  
                    rowClick={null}> 
                    <TextField source="name" label='Название'/> 
                    <DateField source="dt" label="Дата/Время" showTime/>
                    <TextField source="reason" label="Причина"/> 
                    <LinkField label="Устройство" link='device' source="device" optionText="device__eui"/> 
                    <LinkField label="Измеритель" link='meter' source="meter" optionText="meter__serial_number"/> 
                    <TextField source="node__name" label="Узел"/> 
                </Datagrid> 
            ) }
        </List>
    );
}


const EventlShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" label='Название'/> 
            <DateField source="dt" label="Дата/Время"/>
            <TextField source="reason" label="Причина"/>
            <ReferenceField source="meter" reference="meter">
                <TextField source="serial_number" label="Измеритель" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
);


export default {
    list: EventlList, 
    show: EventlShow,
    icon: EventIcon 
};
