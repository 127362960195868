import React from 'react';
import { Fragment } from 'react'; 
import CreateMeters from './CreateMeters';
import { BulkDeleteButton } from 'react-admin'; 
 
const InstallationBulkActionButtons = props => (
    <Fragment>
        <CreateMeters {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);
export default InstallationBulkActionButtons