import React, { useState, useEffect } from 'react'  
import {
  useGetList 
} from "react-admin";
import { YMaps, Map, Placemark } from 'react-yandex-maps'; 
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
 
  yandexMap: { 
    width: "auto",
    height: "70vh",
    marginRight: "auto",
    marginLeft: "auto"
  }

});


// const makeLayout = (layoutFactory, component, contentKey) => {
//     const Layout = layoutFactory.createClass('<div></div>', {
//       build: function() {
//         Layout.superclass.build.call(this);
  
//         Layout.updateReactTree = () => ReactDOM.unstable_renderSubtreeIntoContainer(
//           component,
//           <div>{component.props[contentKey]}</div>,
//           this.getElement().querySelector('div'),
//         );
  
//         Layout.updateReactTree();
//       },
//       clear: function() {
//         Layout.updateReactTree = null;
//         Layout.superclass.clear.call(this);
//       },
//     });
  
//     return Layout;
// };
  
 



// const MyCheckbox = ({color, ...props}) => {  
//     const Chbox = withStyles({
//         root: {
//         color,
//         '&$checked': {
//             color
//         },
//         },
//         checked: {},
//     })((props) => <Checkbox color="default" {...props} />);

//     return <Chbox {...props} /> 
// }


const statusList =  [
  {
    code: 0,  
    color: 'red',
    name: "Не было связи"
  },
  {
    code: 1,  
    color: 'pink',
    name: "Нет связи более 60 дней"
  }, 
  {
    code: 2,  
    color: 'yellow',
    name: "Нет связи более 48 часов"
  }, 
  {
    code : 3,  
    color: 'blue',
    name: "Нет связи более 24 часа"
  }, 
  {
    code: 4,  
    color: 'green',
    name: "Активные за сегодня"
  }, 
]


const Claster = ({data}) => {
        const getBalloonContent = (point) => [
            '<div>',
              '<h3>Измерители</h3>', 
              ...point.meters.map(meter => 
                `<div style="border: 2px solid #DEDEDE; border-radius: 5px; margin: 5px; padding: 5px">
                  <div><a href="#/meter/${meter.id}">Серийный номер: ${meter.serial_number}</a></div>
                  <div>EUI устройства: ${meter.eui}</div>
                  <div>Дата выхода: ${meter.sent_date}</div>
                </div>`
              ),
            '</div>'
          ].join('');
        
        const getHitContent = (point) => (
			`<div style="padding: 5px">
				<h3>Всего ${point.meters.length}</h3>
				<div style="border-bottom: 2px solid green; margin: 5px;">
					Активные: ${point.donut_data[4]}
				</div>
				<div style="border-bottom: 2px solid blue; margin: 5px;">
					Нет связи более 24 часов: ${point.donut_data[3]}
				</div>
				<div style="border-bottom: 2px solid yellow; margin: 5px;">
					Нет связи более 7 дней: ${point.donut_data[2]}
				</div>
				<div style="border-bottom: 2px solid pink; margin: 5px;">
					Нет связи более 30 дней: ${point.donut_data[1]}
				</div>
				<div style="border-bottom: 2px solid red; margin: 5px;">
					Неактивные: ${point.donut_data[0]}
				</div>
			</div>`
        );

        return data.map((point, idx) => (
            point&&<Placemark geometry={[point.lat, point.lng]}
                key={idx}
                properties={{
                    data: Object.entries(point.donut_data).map(([code, count], idx) => ({
                        weight: count,
                        color: statusList[code].color,
                    })),
                    balloonContentBody: getBalloonContent(point),
                    // balloonContentBody: YMaps.templateLayoutFactory.createClass(
                    // '<SimpleDialog  open={addressIndex|raw !== null}onClose={() => setAddressIndex(null)} count={addressIndex|raw !== null && addressList[addressIndex].count|raw} modems={addressIndex|raw !== null && addressList[addressIndex].modems|raw} status={addressIndex|raw !== null && addressList[addressIndex].status|raw} userData={userData|raw}/>'
                    // )
                    // balloonContentBody: YMaps.templateLayoutFactory.createClass('<SimpleDialog  {{open={addressIndex|raw !== null} onClose={() => setAddressIndex(null)} count={addressIndex|raw !== null && addressList[addressIndex].count|raw} modems={addressIndex|raw !== null && addressList[addressIndex].modems|raw} status={addressIndex|raw !== null && addressList[addressIndex].status|raw} userData={userData|raw}}}/>'            )
                    hintContent: getHitContent(point)
                }}
                // Click={() => {setAddressIndex(idx)}}
                options={{
                    iconLayout: 'default#pieChart',
                    iconPieChartRadius: 20,
                    iconPieChartCoreRadius: 15,
                    iconPieChartCoreFillStyle: '#ffffff',
                    iconPieChartStrokeStyle: '#ffffff',
                    iconPieChartStrokeWidth: 1,
                    iconPieChartCaptionMaxWidth: 200
                }}                    
            />))
}


const YandexMeterLocationMap = ({filters, ...props}) => {
    const apiKey = "db24f8e9-c6fe-438a-bc18-e4c125364ba7";
    const map = React.useRef(null);
    const [ flag, setFlag ]  = useState(false);
    const [mapSettings, setMapSettings] = useState({
      zoom: 5,
      coords:{ 
        lat: 48.11, 
        lng: 66.22}, 
    });
    const classes = useStyles();

    useEffect(() => {
      setFlag(false);
    }, [filters])

    const { data, total, isLoading } = useGetList(
        'location', 
        {
          filter: {
            ...filters,
            zoom: mapSettings.zoom,
            bounds: flag ? mapSettings.bounds : undefined
          }
        }
    );
    
   

    useEffect(() => { 
      if (total && !isLoading && !flag) {

        let [lat, lng] = data 
                          .reduce(([lat_sum, lng_sum], {lat, lng}) => [lat_sum + lat, lng_sum + lng], [0,0]);
        lat /= total;
        lng /= total;    
        setMapSettings(
          {
            zoom: data[0].zoom, 
            coords:{ lat, lng }
          }); 
      }
    }, [isLoading]);

    return (   
        <YMaps  query={{apikey: apiKey}}>  
          <Map className={classes.yandexMap}
            width='100%' height='100%'
              instanceRef={ map }
              state={{ center: [ mapSettings.coords.lat, mapSettings.coords.lng], 
                              zoom: mapSettings.zoom
              }} 
              modules={["package.full"]}
              onBoundsChange={
                ({_cache:{newCenter, newZoom, newBounds}}) => {
                      flag && setMapSettings(
                          {
                            zoom: newZoom, 
                            coords:{ 
                              lat: newCenter[0], 
                              lng: newCenter[1]
                            },
                            bounds: `[[${newBounds[0]}],[${newBounds[1]}]]`
                          });
                      setFlag(true); 
                  }
                }
          > 
              { total > 0 &&  <Claster data={ data }/>}
              <div/>   
          </Map>  
        </YMaps>   
    )
}

export default YandexMeterLocationMap