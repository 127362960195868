import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/GetApp';
import {
    Button,
    useDataProvider,
    useNotify,
    useListContext
} from 'react-admin';
import axios from 'axios';

import { saveAs } from 'file-saver';
 
const ExportButton = props => {
    const {
        maxResults = 1000,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        report,
        reportFilterValues,
        ...rest
    } = props;
    const {
        filterValues,
        filter,
        resource,
        sort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props); 

    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify(); 
   
    const handleClick = useCallback(
        event => { 
            const filterValues_ = {...filterValues}
            const reportFilterValues_ = {...reportFilterValues}
            Object.keys(filterValues_).forEach(key => filterValues_[key] === undefined && delete filterValues_[key])
            Object.keys(reportFilterValues_).forEach(key => reportFilterValues_[key] === undefined && delete reportFilterValues_[key])

            axios.defaults.headers.common['Authorization'] = "Token " + localStorage.token;
            axios
                .get(`https://sm.iot-exp.kz/api/v1/${resource}/?`
                        + `${new URLSearchParams(filterValues_).toString()}&`
                        + `${new URLSearchParams(reportFilterValues_).toString()}&`
                        + `${new URLSearchParams(filter).toString()}&`
                        + `ordering=${sort.order === 'ASC' ? "-": ""}${sort.field}&`
                        + `page=1&page_size=${maxResults}&`
                        + `report=${report}`,
                        {responseType: 'blob'}
                    )
                .then(
                    ({ data }) => {
                        saveAs(data, report);
                    }
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', 'warning');
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [
            sort,
            dataProvider,
            exporter,
            filterValues,
            reportFilterValues,
            maxResults,
            notify,
            onClick,
            resource, 
        ]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
    basePath,
    filterValues,
    resource,
    ...rest
}) =>
    rest;

ExportButton.propTypes = {
    basePath: PropTypes.string,
    exporter: PropTypes.func,
    filterValues: PropTypes.object,
    label: PropTypes.string,
    maxResults: PropTypes.number,
    resource: PropTypes.string,
    sort: PropTypes.exact({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    icon: PropTypes.element,
};

export default ExportButton;