import React     from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';  
import AddIcon from '@mui/icons-material/Add';  
import EditIcon from '@mui/icons-material/Edit';  
import DeleteIcon from '@mui/icons-material/Delete';  
import { DeleteButton } from 'react-admin';

export default function ActionMenu({tree, onClickFilterButton, filterButtonChecked}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => { 
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }; 
  if (!tree) return null 
  return (
    <div> 
        <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <MoreVertIcon/>
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
             
                <Button 
                    title="Создать"
                    onClick={() => {
                        navigate("/node/create/" + tree.id);
                    }}>
                    <AddIcon fontSize="small" color="primary"/>
                </Button> 
           
                <Button
                    variant="body2"
                    title="Изменить"
                    onClick={() => { 
                        navigate("/node/" + tree.id);
                    }}
                >
                    <EditIcon fontSize="small" color="primary"/>
                </Button>
          
                <DeleteButton id={tree.id} style={{display:"none"}}  record={tree} source="node" />
                <Button
                    variant="body2" 
                    title="Удалить"
                    onClick={() => document.getElementById(tree.id).click()} 
                    >
                    <DeleteIcon fontSize="small" color="error"/> 
                </Button>
            
        </Menu>
    </div>
  );
}