import PeopleIcon from '@mui/icons-material/People';
import React from 'react';
import { 
    Datagrid,
    List,
    SimpleList, 
    SimpleForm,
    Create, 
    Edit,  
    TextField,
    TextInput,  
    SelectArrayInput, 
    SearchInput,
    required
} from 'react-admin'; 

import { Box, useMediaQuery } from '@mui/material';


const filters = [
    <SearchInput source="q" alwaysOn />,  
    // <SelectInput  
    //     source="groups" 
    //     label='Клиентский сектор'  
    //     choices={[
    //         { id: 1, name: 'Демонстрационный(только чтение)' },
    //         { id: 2, name: 'Конечный пользователь' },
    //     ]}  />
]


export const UserList = (props) => { 
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return(
        <List {...props} filters={filters}>{
            isSmall ? (
                <SimpleList
                    primaryText={record => `ФИО: ${record.last_name} ${record.first_name}`}
                    secondaryText={record => `Логин: ${record.username}, Email: ${record.email}`}
                    linkType={record => "edit"} 
                    />
            ) : (
                <Datagrid rowClick={() => ('edit')}>
                    <TextField source="username" label="Логин" />  
                    <TextField source="first_name" label="Имя"/>
                    <TextField source="last_name" label="Фамилия" />
                    <TextField source="email" label="Почта" type="email"/> 
                    <TextField label="Номер телефона" source="phone"  />
                </Datagrid>
            )
        }
        </List>
    );
}

const UserTitle = ({ record }) => {
    return <span>Доступ/{record ? `${record.username}` : ''}</span>;
};

const UserForm = props => {
 
    return (
        <SimpleForm sanitizeEmptyValues {...props} >
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                >
                    <TextInput source="first_name" label="Имя" fullWidth/>
                    <TextInput source="last_name" label="Фамилия" fullWidth/>
                    <TextInput source="email" label="Почта" type="email" fullWidth/>
                    <TextInput  
                        defaultValue={null} 
                        label="Номер телефона"  
                        source="profile.phone" 
                        type="phone"
                        fullWidth />
                </Box>
                <Box
                    flex={1}
                    mr={{ xs: 0, sm: '0.5em' }}
                >
                    <TextInput source="username" label="Логин" validate={required()} fullWidth/> 
                    <TextInput source="password" label="Пароль" 
                        type="password" 
                        // validate={props.password_required ? required() : undefined} 
                        validate={required()} 
                        fullWidth/> 
                    <SelectArrayInput source="groups" label="Права доступа" validate={required()} fullWidth 
                        choices={[
                            { id: 1, name: 'Демонстрационный(только чтение)' },
                            { id: 2, name: 'Конечный пользователь' },
                        ]}/>
                </Box>
            </Box>
        </SimpleForm>
    )
}

const UserCreate = (props) => { 
    return (
    <Create title={<span>Доступ/Добавить</span>} name="Добавление пользователья" {...props}>
       <UserForm password_required={true}/>
    </Create>
);}

const UserEdit = (props) => (
    <Edit title={<UserTitle/>} name={<UserTitle />} {...props} >
        <UserForm />
    </Edit>
);





export default {
    list: UserList,
    create: UserCreate,
    edit: UserEdit, 
    icon: PeopleIcon,
};
