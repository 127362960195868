import React from 'react'; 
import {
    Datagrid, 
    DateField, 
    TextField,  
} from 'react-admin';   
 
import { Card, CardHeader, CardContent } from '../../../components/CustomCard';  
import CustomChart from '../CustomChart';
import DataStoreBase from '../DataStoreBase';
import { SignalField } from '../../../helpers';

 
const ListChild = props => {     
    return(
        <Datagrid {...props}> 
            <DateField source="dt" label="Дата/Время" showTime/>
            <TextField source="reading" label="Давление, бар"/> 
            <SignalField label="Качество покрытия" />
        </Datagrid>
    )
}
 

const Content = ({ diffReadingPeriod, chartData, meter_id}) => {

    return (   
        <Card>
            <CardHeader title="Давление, бар"/> 
            <CardContent> 
                <CustomChart 
                    key={1}
                    chartData={chartData} 
                    YLabel="Давление, бар"
                    YDataKey="reading"
                    lineColor="black"
                    diffReadingPeriod={diffReadingPeriod}/> 
            </CardContent>
        </Card>     
    )
} 

const DataStorePage = ({ meter_id }) => (
    <DataStoreBase 
        meter_id={meter_id}
        dataGrid={<ListChild/>}>
        <Content meter_id={meter_id}/>
    </DataStoreBase>
)

export default DataStorePage;